import React, { useState, useEffect } from "react";
import "../Component/layout.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NabarCompo from "./navbar";
import Footer from "./Footer";

export default function Home() {
  const [city, setCity] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [activeCity, setActiveCity] = useState("");
  const navigate = useNavigate();

  // 22 image paths array (assuming you have 22 images like "city1.jpg", "city2.jpg", etc.)
  const cityImages = [
    "/assets/city-image/city1.png", 
    "/assets/city-image/city2.png", 
    "/assets/city-image/city3.png", 
    "/assets/city-image/city4.png", 
    "/assets/city-image/city5.png", 
    "/assets/city-image/city6.png", 
    "/assets/city-image/city7.png", 
    "/assets/city-image/city8.png", 
    "/assets/city-image/city9.png", 
    "/assets/city-image/city10.png", 
    "/assets/city-image/city11.png", 
    "/assets/city-image/city12.png", 
    "/assets/city-image/city13.png", 
    "/assets/city-image/city14.png", 
    "/assets/city-image/city15.png", 
    "/assets/city-image/city16.png", 
    "/assets/city-image/city17.png", 
    "/assets/city-image/city18.png", 
    "/assets/city-image/city19.png", 
    "/assets/city-image/city20.png", 
    "/assets/city-image/city21.png", 
    
  ];
  

  useEffect(() => {
    setOpenResetModal(true);
    fetchCities();

    // Check if the screen is mobile and open the modal automatically
    if (window.innerWidth <= 768) {
      setIsMobileModalOpen(true);
    }
  }, []);

  const fetchCities = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        "https://api.vijayhomesuperadmin.in/api/master/getcity"
      );
      if (res.status === 200) {
        const sortedCities = res.data.mastercity.sort((a, b) =>
          a.city.localeCompare(b.city)
        );
        setCity(sortedCities);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCityClick = (city) => {
    setActiveCity(city.city);
    navigate(`/${city.city}`);
  };

  const handleCloseModal = () => {
    setOpenResetModal(false);
  };

  useEffect(() => {
    console.log("Current activeCity:", activeCity);
  }, [activeCity]);

  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <ContentLoader />
        </div>
      ) : (
        <>
          <NabarCompo />
          <Footer />
        </>
      )}

      <Modal
        show={openResetModal}
        centered
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="web-city-modal">
          <div className="modal-header" style={{padding:"0px"}}>
            <img
              src="./assests/locationmap.gif"
              alt="City map"
              style={{ width: "100%", height: "175px" }}
            />
          </div>
          <div className="modal-body p-3">
            <div
              className="title poppins-semibold"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                paddingBottom: "20px",
              }}
            >
              <span>
                <img
                  src="./assests/indiaflg.png"
                  alt="loading..."
                  style={{
                    width: "23px",
                    height: "23px",
                    marginRight: "10px",
                    borderRadius: "50px",
                  }}
                />
              </span>
              India
            </div>
            <div className="city-grid web-view">
              {city.map((city, index) => {
                const imagePath = cityImages[index % cityImages.length]; // Assign image based on the index
                return (
                  <div
                    className="city-item"
                    key={city._id}
                    onClick={() => handleCityClick(city)}
                  >
                    <img
                      src={imagePath} // Image assigned based on index
                      alt={city.city}
                      className="city-image"
                    />
                    <p
                      className={`city-name ${
                        activeCity === city.city ? "active" : ""
                      }`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        fontWeight: ["Bangalore", "Chennai", "Pune","Hyderabad", "Mumbai", "Delhi", "Gurugram"].includes(city.city)
            ? "900" // Make these cities bold
            : "normal", // Default for other cities
          color: ["Bangalore", "Chennai", "Pune","Hyderabad", "Mumbai", "Delhi", "Gurugram"].includes(city.city)
            ? "red" // Make these cities bold
            : "black", // Default for other cities
        
                      }}
                    >
                      {city.city}
                    </p>
                  </div>
                );
              })}
            </div>
            <div
              className="poppins-semibold mt-2"
              style={{ textAlign: "left", padding: "10px" }}
            >
              Coming Soon
            </div>
            <div
              className="d-flex mt-2"
              style={{ justifyContent: "center", paddingLeft: "10px" }}
            >
              <div className="col-md-6">
                <div className=" poppins-semibold ">
                  <span>
                    <img
                      src="./assests/dubai.png"
                      alt="loading..."
                      style={{
                        width: "23px",
                        height: "23px",
                        marginRight: "10px",
                        borderRadius: "50px",
                      }}
                    />
                  </span>
                  Dubai
                </div>
              </div>
              <div className="col-md-6">
                <div className=" poppins-semibold ">
                  <span>
                    <img
                      src="./assests/london.webp"
                      alt="loading..."
                      style={{
                        width: "23px",
                        height: "23px",
                        marginRight: "10px",
                        borderRadius: "50px",
                      }}
                    />
                  </span>
                  London
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Mobile Modal */}
      <Modal
        show={isMobileModalOpen}
        onHide={() => setIsMobileModalOpen(false)}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="mobile-city-modal">
          <div className="modal-header" style={{padding:"0px"}}>
            <img
              src="./assests/locationmap.gif"
              alt="city images"
              className="city_images"
              
            />
          </div>
          <div className="modal-body p-1">
            <div
              className="title poppins-semibold"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                paddingBottom: "20px",
                paddingLeft: "10px",
              }}
            >
              <span>
                <img
                  src="./assests/indiaflg.png"
                  alt="loading..."
                  style={{
                    width: "23px",
                    height: "23px",
                    marginRight: "10px",
                    borderRadius: "50px",
                  }}
                />
              </span>
              India
            </div>
            <div className="city-grid mobile-view" style={{ paddingLeft: "-1px" }}>
              {city.map((city, index) => (
                <div
                  className="city-item"
                  key={city._id}
                  onClick={() => handleCityClick(city)}
                >
                  <img
                    src={cityImages[index % cityImages.length]} // Image assigned based on index
                    alt={city.city}
                    className="city-image"
                    style={{
                      width: "50px",
                      height: "50px",
                      marginBottom: "0px",
                      marginTop: "5px",
                    }}
                    onLoad={() => console.log("Image loaded for city:", city.city, "Image:", city.image)} // Logs when the image is loaded
                    onError={() => console.log("Failed to load image for city:", city.city, "Image:", city.image)} // Logs if the image fails to load
                  />
                  <p
        className={`city-name ${activeCity === city.city ? "active" : ""}`}
        style={{
          fontSize: "16px",
          margin: "0px",
          padding: "0px 5px",
          fontWeight: ["Bangalore", "Chennai", "Pune","Hyderabad", "Mumbai", "Delhi", "Gurugram"].includes(city.city)
            ? "900" // Make these cities bold
            : "normal", // Default for other cities
          color: ["Bangalore", "Chennai", "Pune","Hyderabad", "Mumbai", "Delhi", "Gurugram"].includes(city.city)
            ? "red" // Make these cities bold
            : "black", // Default for other cities
        }}
      >
        {city.city}
      </p>
                </div>
              ))}
            </div>
            <div
              className="poppins-semibold mt-2"
              style={{ textAlign: "left", paddingLeft: "20px" }}
            >
              Coming Soon
            </div>
            <div
              className="d-flex mt-2"
              style={{ justifyContent: "center", gap: "40px", paddingBottom: "40px" }}
            >
              <div className="col-md-6">
                <div className=" poppins-semibold mt-1">
                  <span>
                    <img
                      src="./assests/dubai.png"
                      alt="loading..."
                      style={{
                        width: "23px",
                        height: "23px",
                        marginRight: "10px",
                        borderRadius: "50px",
                      }}
                    />
                  </span>
                  Dubai
                </div>
              </div>
              <div className="col-md-6">
                <div className=" poppins-semibold mt-1">
                  <span>
                    <img
                      src="./assests/london.webp"
                      alt="loading..."
                      style={{
                        width: "23px",
                        height: "23px",
                        marginRight: "10px",
                        borderRadius: "50px",
                      }}
                    />
                  </span>
                  London
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}  