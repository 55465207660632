import React from "react";
import NavbarCompo from "./navbar";
import Footer from "./Footer";
import Homenavbar from "./Homenavbar";
import appliancerepair from "../../src/assests/Appliance-blog.webp";
import packersandmovers from "../../src/assests/vhs-blog.webp";
import keytrends from "../../src/assests/Packers2-blog.webp";
import technological from "../../src/assests/logistic-blog.webp";
import toptips from "../../src/assests/Packers1-blog.webp";
import fridgerepair from "../../src/assests/Fridge Repair 1.jpg";
import acrepair from "../../src/assests/AC Repair 2.jpg";
import packersahmedabad from "../../src/assests/packers-movers-ahmedabad.jpg"; 
import coolcomfort from "../../src/assests/cool-comfort-Ahmedabad.jpg";
import appliancerepairblr from "../../src/assests/HomeAppliance-Bangalore.jpg";
import packersbhubaneshwar from "../../src/assests/Packers-Movers-Bhubaneshwar.jpg";
import moversgurugram from "../../src/assests/movers-gurugram.webp";
import washingmachine from "../../src/assests/Washing-Machine- Repair.webp";
import relocation from "../../src/assests/relocation.jpg";
import electronics from "../../src/assests/electronics.jpg";
import geyser_repair from "../../src/assests/geyser_repair.jpg"
import washing_machine_repair from "../../src/assests/washing_machine_repair.jpg"
import packersmoversgurugram from "../../src/assests/packersmoversgurugram.jpeg"
import delhimove from "../../src/assests/delhimove.jpeg"
import pestcontrolimage from "../../src/assests/pestcontrolimage.webp"
import paintingservicesimage from "../../src/assests/paintingservicesimage.jpg"
import cockroachcontrol from "../../src/assests/cockroachcontrol.png"
import applianceRepairImage from "../../src/assests/applianceRepairImage.jpg";
import applianceSaveMoney from "../../src/assests/applianceSaveMoney.jpg";
import relocationServicesImage from "../../src/assests/relocationServicesImage.jpg";
import  acRepairServicesImage from "../../src/assests/acRepairServicesImage.jpg";
import bedbugcontrol from "../../src/assests/bedbugcontrol.png"
import cleaningServicesImage from "../../src/assests/regularCleaningImage.jpg"
import cleaningblog_29 from "../../src/assests/cleaningblog_29.jpg"
import  cleaningblog_30 from "../../src/assests/cleaningblog_30.jpg"
import deep_regular from "../../src/assests/deep_regular.png"
import deepCleaningImage from "../../src/assests/deepCleaningImage.jpg"
import deliveryPlanningImage from "../../src/assests/deliveryPlanningImage.jpg"
import cleaningScheduleImage from "../../src/assests/cleaningScheduleImage.jpg"
import ecoFriendlyCleaningImage from "../../src/assests/ecoFriendlyCleaningImage.jpg"
import movingChecklistImage from "../../src/assests/movingChecklistImage.jpg"
import energyEfficientACImage from "../../src/assests/energyEfficientACImage.jpg"
import pestControlImageBlog from "../../src/assests/pestControlImageBlog.jpg"
import termiteControlImage from "../../src/assests/termiteControlImage.jpg"
import shippingContainerServicesImage from "../../src/assests/shippingContainerServicesImage.jpg"
import choosing_the_best from "../../src/assests/choosing_the_best.jpg"
import Effective_pcs_in_kochi from "../../src/assests/Effective_pcs_in_kochi.jpg"
import laundry_woes from "../../src/assests/laundry_woes.jpg"
import prof_gyeser_repair from "../../src/assests/prof_gyeser_repair.jpg"
import repair_in_lucknow from "../../src/assests/repair_in_lucknow.jpg"
import top_cs_in_kochi from "../../src/assests/top_cs_in_kochi.jpg"
import trends_future from "../../src/assests/trends_future.jpg"
import Pest_in_vishakhapatnam from "../../src/assests/Pest_in_vishakhapatnam.jpg";
import Pest_in_patna from "../../src/assests/Pest_in_patna.jpg";
import Logistic_innovation from "../../src/assests/Logistic_innovation.jpg";
import pros_cons_pm from "../../src/assests/pros_cons_pm.jpg";
import home_appliance_faridabad from "../../src/assests/home_appliance_faridabad.jpg";
import Ref_repair_ahemdabad from "../../src/assests/Ref_repair_ahemdabad.jpg";
import best_painting_company from "../../src/assests/best_painting_company.jpg";
import Interior_painting_trends2024 from "../../src/assests/Interior_painting_trends2024.jpg";
import local_trans_faridabad from "../../src/assests/local_trans_faridabad.jpg";
import growth_delhi_ncr from "../../src/assests/growth_delhi_ncr.jpg";
import pm_gaziabad from "../../src/assests/pm_gaziabad.jpg";
import pm_hydrabad from "../../src/assests/pm_hydrabad.jpg";

import { Link } from "react-router-dom";

// Function to create SEO-friendly URL
const generateUrl = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9\s]/g, "") // Remove special characters
    .replace(/\s+/g, "-"); // Replace spaces with hyphens
};

// Function to render each blog card
const BlogCard = ({ image, title, description }) => {
  const blogUrl = generateUrl(title);
  return (
    <div className="col-md-4 mb-4">
      <Link to={`/blogdetails/${blogUrl}`} style={{ textDecoration: "none" }}>
        <img src={image} alt={`${title} image`} style={{ width: "100%", height: "200px" }} />
        <div className="poppins-black pt-1" style={{ color: "black" }}>
          {title}
        </div>
        <div className="poppins-regular b-desc">{description}</div>
        <div className="d-flex pt-2">
          <div className="poppins-black" style={{ color: "darkred" }}>
            Read More
          </div>
          <i className="fa-solid fa-arrow-right mx-2 pt-1" style={{ fontSize: "19px", color: "black" }}></i>
        </div>
      </Link>
    </div>
  );
};

const Blog = () => {
  // Define blog data
  const blogs = [
    {
      image: pm_hydrabad,
      title: "Top Packers and Movers in Hyderabad | Call +91-8453748478",
      description: "Vijay Home Services being the best packers and movers in Hyderabad and an overview of different services that professional movers provide within the city, so you can come in contact with the best packing and moving companies such as Vijay Home Services.",
    },
    {
      image: pm_gaziabad,
      title: "Affordable and Efficient Packers and Movers in Ghaziabad",
      description: "Packers and movers in Ghaziabad have been genuine to offer services above any scope of competition within itself and are comprehended as clear contributors to the packing industry. Call at +91-8453748478 for more details.",
    },
    {
      image: growth_delhi_ncr,
      title: "Vijay Home Services: Your Growth Partner in Delhi-NCR You Can Trust",
      description: "The growing business and startup ecosystem of Delhi-NCR is a hub of efficiency, support services and strategic partnerships that are essential in maintaining a trajectory of growth and success",
    },
    {
      image: local_trans_faridabad,
      title: "Local Transport Business in Faridabad: How to Make Rally Efficient",
      description: "Faridabad, one of the most active industrial townships situated in National Capital Region (NCR) India, is a vigour city where businesses are based in an ecosystem of trades and connectivity",
    },
    {
      image: Interior_painting_trends2024,
      title: "Top 10 Interior Painting Trends for 2024 – Transform Your Home Today",
      description: "Discover the top 10 interior painting trends for 2024, from earthy tones and jewel shades to textured walls and eco-friendly paints. Revamp your space with these ideas, and explore professional services like interior painting, texture painting, and wood polish for a flawless finish.",
    },
    {
      image: best_painting_company,
      title: "Top Tips to Choose the Best Painting Company | Expert Guide for Hiring Painters",
      description: "Discover essential tips for selecting the best painting company for your home or business. Learn about the importance of reviews, brand reputation, experience, and more to ensure quality results.",
    },
    {
      image: Ref_repair_ahemdabad,
      title: "Refrigerator Repair Services in Ahmedabad Near You",
      description: "We deliver the best refrigerator repair services across Ahmedabad based on the requirement of the customer. But what makes them the best refrigerator repair service in Ahmedabad. Call for details.",
    },
    {
      image: home_appliance_faridabad,
      title: "Professional Home Appliance Repairs in Faridabad",
      description: "Vijay Home Appliance Services is a reputed name in Faridabad that offers fast, reliable, and affordable services for all types of home appliances. While this is a quick guide, here is a detailed overview of their offerings and why they are the appliance repair service of choice in Faridabad.",
    },
    {
      image: pros_cons_pm,
      title: "Pros and Cons of Packers and Movers: Is It Worth the Investment",
      description: "Hiring Professional Packers and Movers hiring professional packers and movers saves you from the mess of planning and execution of the move. We can all agree that moving is one of the most stressful processes out there.",
    },
    {
      image: Logistic_innovation,
      title: "Logistic Innovation: The Transport Industry's Efficiency Driver",
      description: "Vijay Home Services revolutionising the logistics solutions with industry-proven practices to meet modern-day challenges. Through this article, we talk about the significance of logistic innovation in the transport business",
    },
    {
      image: Pest_in_patna,
      title: "Top 5 Pest Control Services in Patna | Safe & Affordable Solutions",
      description: "Looking for effective pest control in Patna? Discover the top 5 pest control services offering safe, eco-friendly, and affordable solutions for your home and office. Keep pests at bay today!",
    },
    {
      image: Pest_in_vishakhapatnam,
      title: "Top 5 Best Pest Control Services in Visakhapatnam in High Demand",
      description: "Discover the top 5 best pest control services in Visakhapatnam that are in high demand. From eco-friendly solutions to rodent and termite control, find the best pest management services to protect your home and office.",
    },

    {
      image: appliancerepair,
      title: "Vijay Home Appliance Repair Services",
      description: `In today's fast-paced world, household appliances are essential for maintaining comfort and efficiency in our daily lives...`,
    },
    {
      image: packersandmovers,
      title: "Vijay Home Packers and Movers: Your Hassle-Free Moving Partner",
      description: `Moving to a new home can be an exciting experience—new beginnings, a fresh start...`,
    },
    {
      image: keytrends,
      title: "Key Trends Shaping the Packers and Movers Industry in 2024",
      description: "Six key trends shaping the Packers and Movers industry in 2024",
    },
    {
      image: technological,
      title: "Leading Technological Developments in the Logistics Sector",
      description: "The logistics industry has experienced tremendous growth and transformation over the past decade...",
    },
    {
      image: toptips,
      title: "Top Tips for Finding the Best Packers and Movers Deals",
      description: "Relocating can be a daunting task, whether it's moving to a new city or just across town...",
    },
    {
      image: fridgerepair,
      title: "How Can I Have My Refrigerator Fixed in Bangalore?",
      description: "One of the best ways to ease the burden is by hiring professional Vijay packers and movers...",
    },
    {
      image: acrepair,
      title: "Get Professionals to Repair Your AC Quickly in Bangalore",
      description: "When your air conditioner (AC) breaks down, especially during Bangalore’s hot summer months...",
    },
    {
      image: packersahmedabad,
      title: "Packers and Movers in Ahmedabad: Vijay Home Services",
      description: "Relocating can be both an exciting and challenging experience, with endless tasks and a hefty amount of packing and moving to manage. In a bustling city like Ahmedabad, where people are constantly moving in and out for work, business, or family reasons, reliable and efficient packers and movers services have become essential.",
    },
    {
      image: coolcomfort,
      title: "Cool Comfort: Premier AC Service in Ahmedabad",
      description: "In the hot and humid climate of Ahmedabad, air conditioners are essential for comfort and productivity. However, without regular maintenance, air conditioners can become less effective, consume more energy, and even break down when you need them most. Cool Comfort provides a complete solution with Split AC Service in Ahmedabad and Window AC Service in Ahmedabad, designed to keep your home and workplace cool and comfortable year-round.",
    },
    {
      image: packersbhubaneshwar,
      title: "Best Packers and Movers in Bhubaneshwar",
      description: "Bhubaneshwar, the capital of Odisha, is a rapidly growing city that has become a hub for education, business, and tourism. The demand for relocation services is consistently high, thanks to the city's booming development and frequent movement of students, professionals, and businesses. Choosing reliable packers and movers in Bhubaneshwar can make all the difference in ensuring a hassle-free relocation. In this article, we’ll introduce some of the best packers and movers in Bhubaneshwar, explain what makes them stand out, and provide tips to help you choose the best service provider.",
    },
    {
      image: appliancerepairblr,
      title: "Best Appliance Repair Services in Bangalore",
      description: "Bangalore, the IT hub of India, is known for its fast-paced lifestyle and constant demand for reliable home services. With the growing population, the need for efficient appliance repair services has increased. Whether it’s washing machine repairs, refrigerator repairs, or split AC services, having dependable professionals at hand can save time, reduce inconvenience, and extend the life of essential appliances. This article explores some of the best appliance repair services in Bangalore, highlighting providers known for their expertise, affordability, and customer satisfaction.",
    },
    {
      image: moversgurugram,
      title: "Reliable and Affordable Relocation Services in Gurugram",
      description: "Relocating can be an overwhelming experience, filled with both logistical and emotional challenges. Fortunately, in Gurugram, companies like Vijay Home Services are redefining the relocation experience by offering professional, reliable, and affordable movers and packers services. Their dedication to excellence, openness, and client pleasure has established them as a reliable brand in the sector. Here’s an in-depth look at how Vijay Home Services can make your move seamless and stress-free.",
    },
    {
      image: washingmachine,
      title: "Why Regular Washing Machine Maintenance Service is Crucial",
      description: "Regular maintenance of your washing machine is essential for its longevity, efficiency, and overall performance. With frequent use, washing machines are susceptible to wear and tear, which can lead to unexpected breakdowns and costly repairs.",
    },
    {
      image: relocation,
      title: "Best Time to Relocate: An Intracity and Intercity Relocation Tips",
      description: "Relocating, whether within the city or to a new one, involves meticulous planning and preparation. Whether it’s a local shift in areas like HBR Layout or Horamavu in Bangalore,or an intercity move to another state, timing plays a crucial role in ensuring a smooth relocation experience. The best time to relocate can depend on factors like weather, availability of packers and movers, and personal schedules. In this guide, we'll explore expert relocation tips by Vijay Home Service to help you choose the right time for your move and make the process hassle-free.",
    },
    {
      image: electronics,
      title: "Safe House Relocation: How to Keep Your Electronics Safe",
      description: "Relocating a home can be an exciting experience, but it comes with its fair share of challenges. One of the biggest concerns during any move is ensuring the safety of your electronics. These valuable items, from your high-definition television to your sophisticated sound system and computers, are not only expensive but also sensitive to movement, shocks, and temperature fluctuations. For homeowners in HBR Layout and Horamavu, Bangalore, Vijay Home Service, known for its reliable Packers and Movers, can be the perfect partner to ensure a smooth and damage-free relocation.",
    },
    {
      image: geyser_repair,
      title: "Expert Geyser Repair Services in Bangalore by Vijay Home Service",
      description: "A geyser is an essential appliance in every home, especially during the colder months or early mornings. Nothing feels better than a hot shower to start your day or relax after a long one. However, like any other appliance, geysers are prone to issues over time due to constant usage, wear and tear, or lack of regular maintenance. From water not heating up properly to leakage issues, a malfunctioning geyser can disrupt your daily routine.",
    },
    {
      image: washing_machine_repair,
      title: "Bring Back Life to Your Clothes with Washing Machine Repair Services",
      description: "Washing machines have become indispensable in our daily lives, making laundry a hassle-free task. However, when these essential appliances break down, it can disrupt your entire routine. Whether it’s a malfunctioning drum, drainage issues, or a broken motor, a faulty washing machine can cause a great deal of inconvenience.",
    },
    {
      image: packersmoversgurugram,
      title: "Move Your House with Reliable Packers and Movers in Gurugram",
      description: "Moving cities is a stressful decision and in a vibrant city like Gurugram, where every second counts, it becomes even more challenging. ",
    },
    {
      image: delhimove,
      title: "Why You Should Hire Vijay Home Services in Delhi for Your Relocation",
      description: "Choosing a provider that understands the complexities involved in Delhi, with its myriads of neighbourhoods and difficult traffic, is essential. ",
    },
    {
      image: pestcontrolimage,
      title: "Top Pest Control Services | Vijay Home Services for a Pest-Free Home",
      description: "Looking for reliable pest control services? Vijay Home Services ensures your home remains pest-free with expert solutions across various regions. ",
    },
    {
      image: paintingservicesimage,
      title: "Top Painting Services | Vijay Home Services for a Beautiful Home",
      description: "Looking for reliable pest control services? Vijay Home Services ensures your home remains pest-free with expert solutions across various regions. ",
    },
    {
      image: cockroachcontrol,
      title: "Effective Cockroach Control: A Guide by Vijay Home Services",
      description: "Cockroach infestations are a common problem in Indian households. These resilient pests not only invade your living spaces but also pose significant health risks. ",
    },
    {
      image: bedbugcontrol,
      title: "Say Goodbye to Sleepless Nights: Bed Bug Removal with Vijay Home Services",
      description: "Bed bugs may not pose a direct health threat in most cases, but their presence can lead to frustration, irritation, and sleepless nights.",
    },
    {
      image: applianceRepairImage,
      title: "5 Signs That You Need to Seek Professional Home Appliance Repair Service",
      description: "Home appliance is an important part of daily life, it aids to accomplish the home works so easy. However, like any machine, they need maintenance and at times repairs. ",
    },

    {
      image: applianceSaveMoney,
      title: "How Home Appliance Repair Can Help You Save Money and Trouble",
      description: "Home appliances have become increasingly necessary in everyday life, they make household chores much easier to tackle, greatly enhancing our quality of living. ",
    },

    {
      image: relocationServicesImage,
      title: "Quick Impact, Professional Touch: Relocation Services Without Hassle",
      description: "Shifting to a new home or office could be an exciting milestone, but the process of moving comes with a lot of stress, confusion, and chaos.  ",
    },

    {
      image: acRepairServicesImage,
      title: "Fast and Efficient Service: Patna's Trusted AC Repair Experts",
      description: "In a city like Patna, where the temperature soars in scorching summer months, a well-functioning air-conditioner is more than a luxury, it is a necessity.  ",
    },
    

    {
      image: cleaningServicesImage,
      title: "Why Regular Cleaning Services Are Essential for a Healthy Home | Deep Cleaning Experts",
      description: "Discover the health benefits of regular deep cleaning, bathroom cleaning, and kitchen cleaning. Learn how professional cleaning services reduce allergens, prevent mold, and ensure a bacteria-free home for a healthier lifestyle." 
    },
    {
      image: cleaningblog_29 ,
      title: "Top 10 Benefits of Hiring a Professional Cleaning Service for Your Home and Office",
      description: "Discover the top 10 reasons to hire a professional cleaning service. From saving time to improving air quality, learn how expert cleaners can transform your home or office into a spotless and healthy space.  ",
    },

    {
      image: cleaningblog_30 ,
      title: "10 Common Cleaning Mistakes & How Professional Services Can Fix Them | Expert Tips",
      description: "Discover 10 common cleaning mistakes that can harm your home and how professional services can resolve them. From deep cleaning to proper sanitization, learn effective solutions for a spotless and healthy home. Contact Vijay Home Services today!  ",
    },

    {
      image: deep_regular ,
      title: "Deep Cleaning vs. Regular Cleaning: Key Differences & Why Both Matter",
      description: "Discover the essential differences between deep cleaning and regular cleaning. Learn why both are crucial for maintaining a healthy, hygienic space and how to balance them effectively. ",
    },

    
    {
      image: deepCleaningImage ,
      title: "The Ultimate Checklist for Deep Cleaning Your Home Like a Pro",
      description: "Transform your home with this ultimate deep cleaning checklist! Step-by-step guidance to tackle every room or know what to expect from professional cleaning services. ",
    },

    {
      image: deliveryPlanningImage ,
      title: "How to Plan Your Deliveries with Vijay Home Services Bangalore",
      description: "Plan is one of the efficiently delivery tools for business holders and also, we play an essential role to secure and on-time delivery of goods.",
    },
    {
      image: cleaningScheduleImage ,
      title: "How Often Should You Schedule Professional Cleaning? A Room-by-Room Guide",
      description: "Wondering how often to schedule professional cleaning? This room-by-room guide helps you determine the ideal cleaning frequency for your home or office, ensuring a healthy and spotless space.",
    },
    {
      image: ecoFriendlyCleaningImage ,
      title: "Eco-Friendly Professional Cleaning Services: Benefits for Your Home and Health",
      description: "Discover the benefits of eco-friendly professional cleaning services! Learn how green cleaning products and sustainable practices protect your health and the environment while keeping your home spotless.",
    },
    {
      image: movingChecklistImage ,
      title: "Simplify Your Move: Room-by-Room Checklist for Hassle-Free Packing",
      description: "Relocating to a new home can feel overwhelming, but with proper planning and a clear checklist, the process becomes far less daunting. Vijay Home Services is here to guide you through this transition with a comprehensive room-by-room packing checklist designed to simplify your move.",
    },
    {
      image: energyEfficientACImage ,
      title: "Cut Costs, Carbon: Best Practices for Energy-Efficient Air Conditioning",
      description: "In today's world, air conditioning can be considered a necessity — and that is especially the case in countries with scorching weather.",
    },
    {
      image: pestControlImageBlog ,
      title: "Why Professional Pest Control is Better Than DIY | 5 Expert Benefits",
      description: "Discover 5 key reasons why professional pest control is better than DIY. Learn how expert services offer long-term solutions, safety, and effectiveness to keep your home pest-free.",
    },
    {
      image: termiteControlImage ,
      title: "The Ultimate Guide to Professional Termite Control: Signs, Treatment & Prevention",
      description: "Learn how to spot signs of a termite infestation, explore professional treatment options, and discover effective prevention methods. Protect your home from costly termite damage with expert solutions.",
    },

    {
      image: shippingContainerServicesImage,
      title: "Affordable Shipping Container Services in Ahmedabad",
      description: "Efficient logistics and cost-effective transportation are vital for your business and personal needs.",
    },

    {
      image: choosing_the_best,
      title: "Choosing the Best: Appliances Repair Services in Bangalore",
      description: "Bangalore is an active city where home appliances have taken a big space in our day-to-day life.",
    },

    {
      image: Effective_pcs_in_kochi,
      title: "Effective Pest Control Services in Kochi | Your Ultimate Guide to a Pest-Free Home",
      description: "Discover the best pest control services in Kochi. Learn how to keep your home pest-free with expert tips, professional treatments, and prevention strategies.",
    },
    {
      image: repair_in_lucknow,
      title: "Benefits of Professional Refrigerator Repair Services in Lucknow",
      description: "Refrigerators have become a necessity in every household as they preserve the freshness of food and drinks. ",
    },
    {
      image: laundry_woes,
      title: "Say Goodbye to Laundry Woes: Top Washing Machine Repair Services in Noida",
      description: "A washing machine is one of those devices which is a necessity in our daily life and we use that a fair bit. ",
    },
    {
      image: prof_gyeser_repair,
      title: "5 Signs You Should Immediately Call a Professional for Geyser Repair",
      description: "Geysers are perhaps one of the most useful equipment we utilize at home, particularly in winters. ",
    },
    {
      image: top_cs_in_kochi,
      title: "Top Cleaning Services in Kochi | Home & Deep Cleaning Experts",
      description: "Discover top cleaning services in Kochi for spotless homes and offices. Find expert house cleaning, deep cleaning, and home cleaning services in Kochi tailored to your needs.",
    },
    {
      image: trends_future,
      title: "Revolutionizing Truckload Transportation: Trends Shaping the Future",
      description: "Importance of Truckload Transportation: Truckload transportation plays a crucial role in the global supply chain, transitioning goods from manufacturers to the end consumer. ",
    },
    

  ];

  return (
    <div className="">
      <NavbarCompo />
      <Homenavbar />
      <div className="container">
        <div className="row p-2 mb-3">
          <h1 className="poppins-semibold mt-2 mb-3">Blogs</h1>
          {/* Render each blog card */}
          {blogs.map((blog, index) => (
            <BlogCard key={index} image={blog.image} title={blog.title} description={blog.description} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
