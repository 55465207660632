import React, { useEffect, useRef, useState } from "react";
import Footer from "./Footer";
import vicon from "../../src/assets1/v-icon.svg";
import vicon1 from "../../src/assets1/v-icon1.svg";
import vicon2 from "../../src/assets1/v-icon2.svg";
import homebanner from "../../src/assets1/homebanner.jpg";
import vnicon from "../../src/assets1/vnicon.svg";
import vnicon2 from "../../src/assets1/vnicon2.svg";
import vnicon3 from "../../src/assets1/vnicon3.svg";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import axios from "axios";
import { Modal } from "react-bootstrap";
import cityimg from "../../src/assets1/locationmap.webp";
import indiaflg from "../../src/assets1/indiaflg.png";
import dubai from "../src/../assets1/dubai.png";
import london from "../../src/assets1/london.webp";
import phomebanner from "../../src/assets1/phomebanner.jpg";
import { Autocomplete } from "@react-google-maps/api";
import Pheader from "./Pheader";
import Pmheader from "./Pmheader";
import Pmobileheader from "./Pmobileheader";
import { useLocation, useNavigate } from "react-router-dom";
import pcity from "../../src/assets1/pcity.jpg";
import pcity1 from "../../src/assets1/pcity1.jpg";
import pcity2 from "../../src/assets1/pcity2.jpg";
// importing react-helmet
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import pmmobile from "../../src/assets1/pmmobile.jpg"; // Importing image
import howwework from "../../src/assests/howwework.png";

function Packersmovershome() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [name, setname] = useState("");
  const [contact1, setcontact1] = useState("");
  const [email, setemail] = useState("");
  const [comment, setcomment] = useState("");
  const [enquirydate, setenquirydate] = useState(moment().format("MM-DD-YYYY"));
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState(window.location.pathname.split('/')[2] || cityToPass || "Select City");
  const [activeCity, setActiveCity] = useState(window.location.pathname.split('/')[2] || cityToPass || "");
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [shiftingdate, setshiftingdate] = useState("");
  const [customer, setcustomer] = useState("");
  const [selectcategory, setselectcategory] = useState("");
  const [selectAddress, setSelectAddress] = useState(null);
  const [selectAddress1, setSelectAddress1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pickupLocationLat, setPickupLocationLat] = useState(null);
  const [pickupLocationLng, setPickupLocationLng] = useState(null);
  const [dropLocationLat, setDropLocationLat] = useState(null);
  const [dropLocationLng, setDropLocationLng] = useState(null);
  const [pmbannerdata, setpmbannerdata] = useState([]);
  const location = useLocation();
  const [mainContact, setmainContact] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { cityName } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  

  const localutm = localStorage.getItem("utm_source");
  console.log("localutm", localutm);
  const localutmcampaign = localStorage.getItem("utm_campaign");
  console.log("localutmcampaign", localutmcampaign);
  const localutmcontent = localStorage.getItem("utm_content");
  console.log("localutmcontent", localutmcontent);
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  function haversineDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Earth's radius in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }

  const distanceInKm = haversineDistance(
    pickupLocationLat,
    pickupLocationLng,
    dropLocationLat,
    dropLocationLng
  );

  console.log("distanceInKm", distanceInKm);


  const user = localStorage.getItem("user");
  console.log("user", user);
  console.log("cityName===a", cityName);

  // SEO work for Mackers and Movers
  const { cityname } = useParams(); // Fetch city name from URL parameters
  const formattedCity = activeCity
  ? activeCity.charAt(0).toUpperCase() + activeCity.slice(1)
  : "your city";
  const metaTags = {
    Default: {
      title: "Packers and Movers | Call +91-8453748478",
      description:
        "Packers and Movers  offer comprehensive relocation services for residential and commercial needs, specializing in packing, loading, transporting, and unpacking goods with utmost care and efficiency.",
      canonical: "https://vijayhomeservices.com/packers-movers",
      keywords: "Packers and Movers , Movers and Packers ",
      image:
        "https://vijayahomeservices.b-cdn.net/Webp%20Format%E2%80%A6ategory/Packers%20%26%20Movers/Within%20City.webp",
    },
  
    
  };

  const cityMetadata = {
    ahmedabad: {
      title: "Top Packers and Movers in Ahmedabad | Upto ₹1000 OFF",
      description:
        "Reliable packers and movers in Ahmedabad for house shifting, society relocation, and domestic moves. Affordable rates and expert services. Call now at 8453748478!",
      keywords:
        "Packers and Movers Ahmedabad, House Shifting Ahmedabad, Home Shifting Services Ahmedabad",
    },
    bangalore: {
      title: "Packers and Movers in Bangalore | House Shifting Services ₹1999 onwards",
      description:
        "Book professional packers and movers in Bangalore with up to ₹1000 OFF on your first booking. Top home shifting services near you. Call 8453748478 today!",
      keywords:
        "Packers and Movers Bangalore, House Shifting Bangalore, Relocation Services Bangalore",
    },
    bhubaneswar: {
      title: "Packers and Movers Services in Bhubaneswar | Affordable Relocation",
      description:
        "Trusted packers and movers in Bhubaneswar for local and domestic relocation. House shifting services at competitive rates. Call 8453748478 now!",
      keywords:
        "Packers and Movers Bhubaneswar, Home Shifting Bhubaneswar, House Relocation Bhubaneswar",
    },
    chennai: {
      title: "Packers and Movers in Chennai | House Shifting ₹1000 OFF",
      description:
        "Get expert packers and movers in Chennai for hassle-free house shifting. Affordable rates and professional services. Call 8453748478 today!",
      keywords:
        "Packers and Movers Chennai, Home Shifting Chennai, House Relocation Chennai",
    },
    delhi: {
      title: "Best Packers and Movers in Delhi | Upto ₹1000 OFF on Booking",
      description:
        "Book top packers and movers in Delhi for house shifting and society relocation. Affordable pricing and reliable services. Call 8453748478 now!",
      keywords:
        "Packers and Movers Delhi, House Shifting Delhi, Relocation Services Delhi",
    },
    faridabad: {
      title: "Packers and Movers Services in Faridabad | Affordable Relocation",
      description:
        "Expert packers and movers in Faridabad for home shifting and relocation services. Up to ₹1000 OFF on your first booking. Call now at 8453748478!",
      keywords:
        "Packers and Movers Faridabad, Home Shifting Faridabad, Relocation Services Faridabad",
    },
    ghaziabad: {
      title: "Packers and Movers in Ghaziabad | House Shifting Experts",
      description:
        "Reliable packers and movers in Ghaziabad for society relocation and house shifting. Affordable rates and quick service. Call 8453748478 today!",
      keywords:
        "Packers and Movers Ghaziabad, Home Shifting Ghaziabad, Relocation Services Ghaziabad",
    },
    gurugram: {
      title: "Top Packers and Movers in Gurugram | Trusted Relocation Services",
      description:
        "Hire professional packers and movers in Gurugram for home shifting and domestic relocation. Affordable pricing with up to ₹1000 OFF. Call 8453748478 now!",
      keywords:
        "Packers and Movers Gurugram, House Shifting Gurugram, Relocation Services Gurugram",
    },
    hyderabad: {
      title: "Packers and Movers Services in Hyderabad | ₹1000 OFF Booking",
      description:
        "Professional packers and movers in Hyderabad for home shifting and society relocation. Affordable and trusted services. Call 8453748478 now!",
      keywords:
        "Packers and Movers Hyderabad, Home Shifting Hyderabad, Relocation Services Hyderabad",
    },
    kochi: {
      title: "Packers and Movers in Kochi | Affordable Relocation Services",
      description:
        "Book expert packers and movers in Kochi for home and society relocation. Competitive pricing with up to ₹1000 OFF on first booking. Call 8453748478!",
      keywords:
        "Packers and Movers Kochi, Home Shifting Kochi, House Relocation Kochi",
    },
    kolkata: {
      title: "Packers and Movers Services in Kolkata | Top Relocation Experts",
      description:
        "Reliable packers and movers in Kolkata for house shifting and society relocation. Same-day service available. Call 8453748478 now!",
      keywords:
        "Packers and Movers Kolkata, House Shifting Kolkata, Relocation Services Kolkata",
    },
    lucknow: {
      title: "Packers and Movers in Lucknow | House Shifting ₹1999 onwards",
      description:
        "Get top-rated packers and movers in Lucknow for domestic relocation and house shifting. Affordable rates. Call Vijay Home Services at 8453748478!",
      keywords:
        "Packers and Movers Lucknow, Home Shifting Lucknow, Relocation Services Lucknow",
    },
    mumbai: {
      title: "Top Packers and Movers in Mumbai | Upto ₹1000 OFF Booking",
      description:
        "Book reliable packers and movers in Mumbai for house shifting and domestic relocation. Affordable pricing and quick services. Call 8453748478 now!",
      keywords:
        "Packers and Movers Mumbai, House Shifting Mumbai, Home Relocation Mumbai",
    },
    mysore: {
      title: "Packers and Movers in Mysore | Trusted Home Shifting Services",
      description:
        "Expert packers and movers in Mysore for house shifting and society relocation. Affordable pricing and trusted experts. Call 8453748478 today!",
      keywords:
        "Packers and Movers Mysore, House Shifting Mysore, Relocation Services Mysore",
    },
    noida: {
      title: "Packers and Movers in Noida | Top Shifting Services ₹1999 onwards",
      description:
        "Reliable packers and movers in Noida for domestic relocation and home shifting services. Call 8453748478 for up to ₹1000 OFF!",
      keywords:
        "Packers and Movers Noida, Home Shifting Noida, Relocation Services Noida",
    },
    patna: {
      title: "Packers and Movers Services in Patna | Home Relocation ₹1999 onwards",
      description:
        "Trusted packers and movers in Patna for house shifting and domestic relocation. Affordable rates and quick services. Call 8453748478 now!",
      keywords:
        "Packers and Movers Patna, Home Shifting Patna, House Relocation Patna",
    },
    pune: {
      title: "Packers and Movers in Pune | Upto ₹1000 OFF House Shifting",
      description:
        "Affordable packers and movers in Pune for house shifting and society relocation. Call Vijay Home Services at 8453748478 today!",
      keywords:
        "Packers and Movers Pune, Home Shifting Pune, Relocation Services Pune",
    },
    surat: {
      title: "Packers and Movers Services in Surat | Trusted Relocation Experts",
      description:
        "Expert packers and movers in Surat for house shifting and domestic relocation. Affordable rates. Call 8453748478 today!",
      keywords:
        "Packers and Movers Surat, Home Shifting Surat, House Relocation Surat",
    },
    vadodara: {
      title: "Packers and Movers in Vadodara | Affordable House Shifting",
      description:
        "Reliable packers and movers in Vadodara for home and society relocation. Quick service and competitive rates. Call 8453748478 now!",
      keywords:
        "Packers and Movers Vadodara, House Shifting Vadodara, Relocation Services Vadodara",
    },
    vizag: {
      title: "Top Packers and Movers in Vizag | Domestic Relocation ₹1999 onwards",
      description:
        "Book trusted packers and movers in Vizag for home shifting and domestic relocation. Affordable pricing and expert services. Call 8453748478 now!",
      keywords:
        "Packers and Movers Vizag, Home Shifting Vizag, House Relocation Vizag",
    },
  };



  const metadata = cityMetadata[formattedCity.toLowerCase()] || {
    title: `Packers and Movers in ${formattedCity} | Call +91-8453748478`,
    description: `Packers and Movers in ${formattedCity} offer comprehensive relocation services for residential and commercial needs, specializing in packing, loading, transporting, and unpacking goods with utmost care and efficiency.`,
    keywords: `Packers and Movers in ${formattedCity}, Movers and Packers ${formattedCity}, Relocation Services ${formattedCity}`,
    ogImage: "https://vijayahomeservices.b-cdn.net/default-packers-movers.webp",
  };


 

  const [Data, setData] = useState([]);
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const handleResetModal = () => {
    setOpenResetModal(true);
  };

  useEffect(() => {
    // Scrolls to the top on component mount
    window.scrollTo(0, 0);
  }, []); // No dependencies, so it runs once on mount

  useEffect(() => {
    // Calls getService function when the component mounts
    getService();
  }, []); // No dependencies, so it runs once on mount

  //useEffect(() => {
  //window.scrollTo(0, 0);
  //}, []);

  //useEffect(() => {
  //getService();
  //}, []);
  

  const getService = async () => {
    try {
      let res = await axios.get(
        "https://pm.vijayhomeservice.in/api/service/getservice"
      );
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (er) {
      console.log(er, "err while fetching data");
    }
  };

  const addEnquiry = async (e) => {
    e.preventDefault();

    if (!customer || !mobilenumber || !selectcategory) {
      alert("Please enter all fields");
    } else {
      try {
        const config = {
          url: "/enquiry/addenquiry",
          method: "post",
          baseURL: "https://pm.vijayhomeservice.in/api",

          headers: { "content-type": "application/json" },
          data: {
            date: enquirydate,
            customer: customer,
            contact1: mobilenumber,
            category: selectcategory,
            type: "Packers Movers",
            city: selectedCity,
          },
        };

        const response = await axios(config);

        if (response.status === 200) {
          const data = response.data.data;
          alert("Enquiry added successfully:", data);
          setShow(false);
          window.location.reload("");
        }
      } catch (error) {
        console.error("Error adding enquiry:", error);
      }
    }
  };

  useEffect(() => {
    getCity();
  }, []);

  const getCity = async () => {
    try {
      let res = await axios.get(
        "https://api.vijayhomesuperadmin.in/api/master/getcity"
      );
      if (res.status === 200) {
        const sortedCities = res.data.mastercity.sort((a, b) =>
          a.city.localeCompare(b.city)
        );
        setCity(sortedCities);
      }
    } catch (er) {
      console.log(er, "err while fetching data");
    }
  };

  const handleChange = (city) => {
    setOpenResetModal(false);
    setActiveCity(city.city);
    setSelectedCity(city.city);
  };

  const autocompletePickupRef = useRef(null);
  const autocompleteDropRef = useRef(null);

  const cityToPass = selectedCity || cityName;
  // Add this useEffect to watch for cityToPass changes
  useEffect(() => {
    if (cityToPass) {
      // Only update the URL without navigation
      const currentPath = window.location.pathname;
      const newPath = currentPath.replace(
        /packers-movers\/[^/]+/, 
        `packers-movers/${cityToPass.toLowerCase()}`
      );
      window.history.replaceState(null, '', newPath);
    }
  }, [cityToPass]);
  const addpackersenquiry = async (e) => {
    //e.preventDefault();
    if (!cityToPass) {
      alert("Please select a city");
      return;
    }

    if (!pickupLocation || !mobilenumber || !shiftingdate) {
      alert("Please enter all fields");
    }
    if (!/^\d{10}$/.test(mobilenumber)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    } else {
      setLoading(true);
      try {
        const config = {
          url: "/enquiry/addenquiry",
          method: "post",
          baseURL: "https://pm.vijayhomeservice.in/api",
          headers: { "content-type": "application/json" },
          data: {
            pickupLocation: selectAddress,
            dropLocation: selectAddress1,
            city: cityToPass,
            contact1: mobilenumber,
            reference: "Packers Movers",
            serviceDate: shiftingdate,
            userId: user?._id,
            type: "website",
            distance: distanceInKm,
          },
        };
        const response = await axios(config);

        if (response.status === 200) {
          const data = response.data.data;
        //  alert("Please select the list of items", data);
          // window.location.reload("");
          handleSubmit();
        }
      } catch (error) {
        console.error("Error adding enquiry:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // const handleSubmit = () => {
  //   if (!cityToPass) {
  //     alert("Please select a city");
  //     return;
  //   }

  //   if (!pickupLocation || !dropLocation || !mobilenumber || !shiftingdate) {
  //     alert("Please enter all fields");
  //   } else {
  //     navigate("/packers-movers-services-details", {
  //       state: {
  //         pickupLocation,
  //         dropLocation,
  //         mobilenumber,
  //         shiftingdate,
  //         selectedCity: cityToPass,
  //         pickupLocationLat,
  //         pickupLocationLng,
  //         dropLocationLat,
  //         dropLocationLng,
  //         cityData,
  //       },
  //     });
  //   }
  // };

  const handleSubmit = () => {
   // if (!user) {
     // navigate("/pmlogin", {
       // state: {
         // pickupLocation,
          //dropLocation,
          //mobilenumber,
          //shiftingdate,
         // selectedCity: cityToPass,
       //   pickupLocationLat,
        //  pickupLocationLng,
       //   dropLocationLat,
        //  dropLocationLng,
         // cityData,
       // },
     // });
      //return;
    //}

    if (!cityToPass) {
      alert("Please select a city");
      return;
    }

    if (!pickupLocation || !mobilenumber || !shiftingdate) {
      alert("Please enter all fields");
    } else {
      navigate("/packers-movers-services-details", {
        state: {
          pickupLocation,
          dropLocation,
          mobilenumber,
          shiftingdate,
          selectedCity: cityToPass,
          pickupLocationLat,
          pickupLocationLng,
          dropLocationLat,
          dropLocationLng,
          cityData,
        },
      });
    }
  };

  const bannerdata = [
    
    { id: 1, webbanner: pcity1 },
    { id: 2, webbanner: pcity2 },
  ];

  const [cityData, setCityData] = useState(null);

  // Use useEffect to watch for changes in Data
  useEffect(() => {
    if (Array.isArray(Data) && Data.length > 0) {
      // Ensure Data is a valid array
      setCityData(Data[0]); // Set the initial city data
    }
  }, [Data]); // Dependency on Data, so effect runs when Data changes

  // Handler to update city data based on selected city type
  const handleCity = (cityType) => {
    setCityData(cityType); // Update city data when a city is selected
  };

  useEffect(() => {
    getbanner();
  }, []);

  const getbanner = async () => {
    try {
      const res = await axios.get(
        "https://api.vijayhomeservice.com/api/pmbanner/getallpmbanner"
      );
      setpmbannerdata(res.data.banner);
    } catch (error) {
      console.log("Error in getbanner:", error);
    }
  };

  console.log("cityData", cityData);
  // Adding meta tags for SEO

  // Return statements for SEO Packers and movers
  const sendOTP = async () => {
    // Validate mobile number
    const isValidMobile = /^[6-9]\d{9}$/.test(mainContact);
    if (!isValidMobile) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }

    // if (!customername) {
    //   alert("Please enter a valid name.");
    //   return;
    // }

    // Extract UTM parameters
    const queryParams = new URLSearchParams(window.location.search);
    const utmSource = queryParams.get("utm_source") || "";
    const utmMedium = queryParams.get("utm_medium") || "";
    const utmCampaign = queryParams.get("utm_campaign") || "";
    const utmContent = queryParams.get("utm_content") || "";
    const gclid = queryParams.get("gclid") || "";
    const referringDomain = document.referrer || "";

    // Recognized social networks
    const socialNetworks = [
      "facebook.com",
      "twitter.com",
      "linkedin.com",
      "instagram.com",
    ];

    // Determine the Tag value
    let Tag = "";

    // Logic for setting Tag
    if (
      (utmMedium === "social" &&
        socialNetworks.some((network) => referringDomain.includes(network))) ||
      (utmMedium === "social" && socialNetworks.includes(utmSource))
    ) {
      Tag = "Organic social";
    } else if (
      gclid ||
      utmSource.toLowerCase().includes("adword") ||
      utmSource.toLowerCase().includes("ppc") ||
      utmSource.toLowerCase().includes("cpc") ||
      (utmMedium.toLowerCase().includes("search") &&
        utmSource.toLowerCase().includes("google")) ||
      (utmSource === "google.com" && (utmMedium || utmCampaign))
    ) {
      Tag = "Paid search";
    } else if (
      (utmMedium.toLowerCase().includes("paid") ||
        utmMedium.toLowerCase().includes("ppc") ||
        utmMedium.toLowerCase().includes("cpc")) &&
      socialNetworks.some(
        (network) => utmSource === network || referringDomain.includes(network)
      )
    ) {
      Tag = "Paid social";
    } else if (
      socialNetworks.some((network) => referringDomain.includes(network))
    ) {
      Tag = "Organic social";
    } else {
      console.warn("No Tag value identified for the current context.");
    }

    localStorage.setItem("Tag", Tag);

    setIsLoading(true);

    // Send OTP
    try {
      const response = await axios.post(
        "https://api.vijayhomeservicebengaluru.in/api/sendotp/sendByCartBookweb",
        {
          mainContact: mainContact,
          // customerName: customername,
          reference1: localutm,
          reference2: localutmcampaign,
          reference3: localutmcontent,
          Tag: Tag,
        }
      );

      if (response.status === 200) {
        // alert("Successful login");
        localStorage.setItem("user", JSON.stringify(response.data.user));
        setShowLoginModal(false); // Close the modal
        handleSubmit();
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        alert(error.response.data.error);
      } else if (error.message) {
        alert(`Error: ${error.message}`);
      } else {
        alert("An unknown error occurred. Please try again.");
      }
      console.error("Error details:", error);
    } finally {
      // Stop the loader
      setIsLoading(false);
    }
  };
  // 22 image paths array (assuming you have 22 images like "city1.jpg", "city2.jpg", etc.)
const cityImages = [
  "/assets/city-image/city1.png", 
  "/assets/city-image/city2.png", 
  "/assets/city-image/city3.png", 
  "/assets/city-image/city4.png", 
  "/assets/city-image/city5.png", 
  "/assets/city-image/city6.png", 
  "/assets/city-image/city7.png", 
  "/assets/city-image/city8.png", 
  "/assets/city-image/city9.png", 
  "/assets/city-image/city10.png", 
  "/assets/city-image/city11.png", 
  "/assets/city-image/city12.png", 
  "/assets/city-image/city13.png", 
  "/assets/city-image/city14.png", 
  "/assets/city-image/city15.png", 
  "/assets/city-image/city16.png", 
  "/assets/city-image/city17.png", 
  "/assets/city-image/city18.png", 
  "/assets/city-image/city19.png", 
  "/assets/city-image/city20.png", 
  "/assets/city-image/city21.png", 
  "/assets/city-image/city22.png"
];

  return (
    <div>
       <Helmet>
    <title>{metadata.title}</title>
    <meta name="description" content={metadata.description} />
    <meta name="keywords" content={metadata.keywords} />
    <link
      rel="canonical"
      href={`https://vijayhomeservices.com/packers-movers/${formattedCity}`}
    />
    <meta property="og:title" content={metadata.title} />
    <meta property="og:description" content={metadata.description} />
    <meta
      property="og:url"
      content={`https://vijayhomeservices.com/packers-movers/${formattedCity}`}
    />
    <meta property="og:image" content={metadata.ogImage} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={metadata.title} />
    <meta name="twitter:description" content={metadata.description} />
    <meta name="twitter:image" content={metadata.ogImage} />
  </Helmet>
    

      <div className="">
        <Pheader />
        <Pmobileheader />

        <div className="pm-web">
          <div
            id="carouselExample"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="3000"
          >
            <div className="carousel-inner">
              {pmbannerdata.map((data, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                >
                  <img
                    src={data.webbanner}
                    className="d-block w-100"
                    alt={`Banner ${index + 1}`}
                    style={{ height: "250px", width: "100%" }}
                  />
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div className="pm-mobile">
  
          <div
            id="carouselExample1"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="3000"
          >
            <div className="carousel-inner">
              {pmbannerdata.map((data, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                >
                  <img
                    src={data.mobilebanner}
                    className="d-block w-100"
                    alt={`Banner ${index + 1}`}
                    style={{ height: "200px", width: "100%" }}
                  />
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample1"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample1"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div className="container">
        <div className="pm-web">         
           <div
            className="d-flex mt-3 mb-3"
            style={{ justifyContent: "center", gap: "10px" }}
          >
            {Data.slice(0, 3).map((data) => (
              <>
                <div className="col-md-3">
                  <div
                    style={{
                      border: "1px solid lightgrey",
                      fontWeight: "bold",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor:
                        cityData?.servicename === data.servicename
                          ? "blue"
                          : "white",
                      color:
                        cityData?.servicename === data.servicename
                          ? "white"
                          : "black",
                      cursor: "pointer",
                    }}
                    onClick={() => handleCity(data)}
                    className="poppins-regular text-center"
                  >
                    {data.servicename}
                  </div>
                </div>
              </>
            ))}
           {/*  <div className="col-md-3">
              <div
                style={{
                  border: "1px solid lightgrey",
                  fontWeight: "bold",
                  padding: "5px",
                  borderRadius: "5px",
                  backgroundColor:
                    cityData?.servicename === "Hire Vehicle" 
                    ? "blue" 
                    : "white",
                  color:
                    cityData?.servicename === "Hire Vehicle"
                      ? "white"
                      : "black",
                  cursor: "pointer",
                }}
                onClick={() => handleCity({ servicename: "Hire Vehicle" })}
                className="poppins-regular text-center"
              >
                Hire Vehicle
                </div>     
                   </div> */}
            </div>
          </div>


          <div className="pm-mobile">
            <div
              className="d-flex  mb-3"
              style={{ justifyContent: "center", gap: "10px" }}
            >
              {Data.slice(0, 3).map((data) => (
                <>
                  <div className="col-md-3">
                    <div
                      style={{
                        border: "1px solid lightgrey",
                        fontWeight: "bold",
                        padding: "9px 7px",
                        borderRadius: "5px",
                        backgroundColor:
                          cityData?.servicename === data.servicename
                            ? "blue"
                            : "white",
                        color:
                          cityData?.servicename === data.servicename
                            ? "white"
                            : "black",
                        cursor: "pointer",
                        fontSize: "12px",
                      }}
                      onClick={() => handleCity(data)}
                      className="poppins-light text-center"
                    >
                      {data.servicename}
                    </div>
                  </div>
                </>
              ))}
              
                </div>
              </div>
            
<div className="">
            <div className="row mt-3">
              <div
                onClick={handleResetModal}
                className="col-md-2 mb-3"
                style={{
                  backgroundColor: "aliceblue",
                  padding: "20px",
                }}
              >
                <div className="poppins-black" style={{ color: "darkred" }}>
                  <span>
                    <i
                      className="fa-solid fa-location-dot mx-2"
                      style={{ fontSize: "15px" }}
                    ></i>
                  </span>
                  {selectedCity}
                  <span className="" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </div>
              </div>
            </div>
            <div
              className="row mb-2"
              style={{
                backgroundColor: "aliceblue",
                padding: "15px",
                justifyContent: "center",
                borderRadius: "5px",
              }}
            >
              <div className="col-md-3">
                <div className="poppins-black">
                  Pickup Location{" "}
                  <span className="" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </div>

                <Autocomplete
                  onLoad={(autocomplete) => {
                    autocompletePickupRef.current = autocomplete; // Use pickup ref
                  }}
                  onPlaceChanged={() => {
                    const place = autocompletePickupRef.current.getPlace();
                    if (!place || !place.geometry) {
                      alert("Please select a valid location.");
                      return;
                    }

                    const formattedAddress =
                      place.formatted_address || "Unknown address";

                    setPickupLocation(formattedAddress);
                    setSelectAddress(formattedAddress);

                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    setPickupLocationLat(lat);
                    setPickupLocationLng(lng);
                  }}
                >
                  <input
                    type="text"
                    className="poppins-black col-md-12"
                    placeholder="Sending from"
                    value={pickupLocation}
                    onChange={(e) => setPickupLocation(e.target.value)}
                    style={{
                      color: "grey",
                      fontSize: "12px",
                      border: "none",
                      outline: "none",
                      width: "100%",
                      padding: "8px",
                      marginTop: "5px",
                      height: "50px",
                    }}
                  />
                </Autocomplete>
              </div>

              {cityData?.servicename === "With in Society" ? null : (
              <div className="col-md-3">
                <div className="poppins-black">
                  Drop Location{" "}
                  <span className="" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </div>
                <Autocomplete
                  onLoad={(autocomplete) => {
                    autocompleteDropRef.current = autocomplete;
                  }}
                  onPlaceChanged={() => {
                    const place = autocompleteDropRef.current.getPlace();
                    if (!place || !place.geometry) {
                      alert("Please select a valid location.");
                      return;
                    }

                    const formattedAddress =
                      place.formatted_address || "Unknown address";

                    setDropLocation(formattedAddress);
                    setSelectAddress1(formattedAddress);
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    setDropLocationLat(lat);
                    setDropLocationLng(lng);
                  }}
                >
                  <input
                    type="text"
                    className="poppins-black col-md-12"
                    placeholder="Sending to"
                    value={dropLocation}
                    onChange={(e) => setDropLocation(e.target.value)}
                    style={{
                      color: "grey",
                      fontSize: "12px",
                      border: "none",
                      outline: "none",
                      width: "100%",
                      padding: "8px",
                      marginTop: "5px",
                      height: "50px",
                    }}
                  />
                </Autocomplete>
              </div>

)}
<div className="col-md-3">
                <div className="poppins-black">
                  Phone Number
                  <span className="" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </div>
                <input
                  type="tel"
                  className="poppins-black"
                  placeholder="Enter Contact Details"
                  value={mobilenumber}
                  // onChange={(e) => {
                  //   // Only allow digits (0-9) to be entered
                  //   const value = e.target.value;
                  //   if (/^\d*$/.test(value)) {
                  //     setMobilenumber(value);
                  //   }
                  // }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,10}$/.test(value)) {
                      setMobilenumber(value);
                    }
                  }}
                  maxLength="10"
                  style={{
                    color: "grey",
                    fontSize: "12px",
                    border: "none",
                    outline: "none",
                    width: "100%",
                    padding: "8px",
                    marginTop: "5px",
                    height: "50px",
                  }}
                />
              </div>
              <div className="col-md-3">
                <div className="poppins-black">
                  Shifting Date
                  <span className="" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </div>
                <input
                  type="date"
                  className="poppins-black col-md-12"
                  placeholder="Please Select Date"
                  value={shiftingdate}
                  onChange={(e) => setshiftingdate(e.target.value)}
                  onFocus={(e) => e.target.showPicker()}
                  style={{
                    color: "grey",
                    fontSize: "12px",
                    border: "none",
                    outline: "none",
                    width: "100%",
                    padding: "8px",
                    marginTop: "5px",
                    marginBottom: "10px",
                    height: "50px",
                  }}
                />
              </div>
              <div
                className="col-md-3 mt-2"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  // onClick={handleSubmit}
                  // onClick={addpackersenquiry}
                  onClick={() => {
                    if (!user) {
                      setShowLoginModal(true); // Open login modal if user is not logged in
                    } else {
                      addpackersenquiry(); // Call handleSubmit directly if user is logged in
                    }
                  }}
                  className="poppins-black text-center btn  btn-danger"
                  style={{
                    color: "white",
                    // backgroundColor: "darkred",
                    padding: "10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  {loading ? "Loading..." : "Check Price"}
                </div>
              </div>
            </div>
          </div>
          <div className="pm-web">
          {/* First Module */}
          <div className="row mt-5 mb-3">
            <div className="poppins-semibold text-center">
              House Shifting Services with Best Packers and Movers in {formattedCity}
            </div>

            <div className="col-md-4 mt-5">
              <div
                className=""
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img src={vicon} alt="loading..." />
              </div>
              <div className="poppins-black text-center pt-2">
                On-time shifting
              </div>
              <div className="poppins-regular text-center pt-1">
                Experience reliable packers and movers services
              </div>
            </div>

            <div className="col-md-4 mt-5">
              <div
                className=""
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img src={vicon1} alt="loading..." />
              </div>
              <div className="poppins-black text-center pt-2">
                Economical prices
              </div>
              <div className="poppins-regular text-center pt-1">
                Hassle-free packers and movers services that fit your budget
              </div>
            </div>

            <div className="col-md-4 mt-5">
              <div
                className=""
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img src={vicon2} alt="loading..." />
              </div>
              <div className="poppins-black text-center pt-2">
                Damage-proof packaging
              </div>
              <div className="poppins-regular text-center pt-1">
                Multi-layered packing to ensure safe movement of household goods
              </div>
            </div>
          </div>

          <img
            src={phomebanner}
            style={{ width: "100%", height: "auto" }}
            alt="loading"
            className="mt-3 mb-2"
          />

          <div className="row mt-5 mb-3">
            <div className="poppins-semibold text-center">
              What Sets Us Apart:{" "}
              <span
                className="poppins-semibold"
                style={{ color: "darkred", fontWeight: "bold" }}
              >
                Our Strengths
              </span>
            </div>

            <div className="col-md-4 mt-4">
              <div
                className="poppins-semibold"
                style={{ color: "darkred", textAlign: "center" }}
              >
                15+
              </div>
              <div className="poppins-regular text-center">Years of Trust</div>
            </div>

            <div className="col-md-4 mt-4">
              <div
                className="poppins-semibold"
                style={{ color: "darkred", textAlign: "center" }}
              >
                2000+
              </div>
              <div className="poppins-regular text-center">Moves Monthly</div>
            </div>

            <div className="col-md-4 mt-4">
              <div
                className="poppins-semibold"
                style={{ color: "darkred", textAlign: "center" }}
              >
                150+
              </div>
              <div className="poppins-regular text-center">Own Vehicles</div>
            </div>

            <div className="col-md-4 mt-4">
              <div
                className="poppins-semibold"
                style={{ color: "darkred", textAlign: "center" }}
              >
                300+
              </div>
              <div className="poppins-regular text-center">In-House Team</div>
            </div>

            <div className="col-md-4 mt-4">
              <div
                className="poppins-semibold"
                style={{ color: "darkred", textAlign: "center" }}
              >
                21+
              </div>
              <div className="poppins-regular text-center">Cities In India</div>
            </div>

            <div className="col-md-4 mt-4">
              <div
                className="poppins-semibold"
                style={{ color: "darkred", textAlign: "center" }}
              >
                40M
              </div>
              <div className="poppins-regular text-center">
                Trusted Customer
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-3" style={{ justifyContent: "center" }}>
            <div className="col-md-8">
              <div className="poppins-semibold text-center">Why Choose Us?</div>

              <ul className="mt-3">
                <li className="poppins-regular">
                  <span className="poppins-black">
                    Lowest Price Guarantee:{" "}
                  </span>
                  We offer unbeatable prices. If you find a lower quote, we'll
                  match it.
                </li>
                <li className="poppins-regular pt-2">
                  <span className="poppins-black"> Best Quality Service: </span>
                  Your belongings are in safe hands with our reliable packaging
                  and moving services.
                </li>

                <li className="poppins-regular pt-2">
                  <span className="poppins-black"> Flexibility: </span>
                  Need to reschedule? No problem. Change your moving date to
                  suit your needs.
                </li>

                <li className="poppins-regular pt-2">
                  <span className="poppins-black"> Excellent Support: </span>
                  Our dedicated team is always ready to assist you with any
                  questions.
                </li>
                <li className="poppins-regular pt-2">
                  <span className="poppins-black">
                    Professional Expertise:{" "}
                  </span>
                  Our skilled movers ensure your items are packed and
                  transported with care
                </li>
              </ul>
            </div>
          </div>

          {/* First Module Completed */}
          {/* Second Module */}
          <div
            className="row mt-4 mb-3 "
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div className="poppins-semibold text-center">
              Need an Extra Hand?
            </div>
            <div className="poppins-regular text-center">
              Need more than just packing and moving? We've got you covered.
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4 mt-3" onClick={handleShow}>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <img
                  src={vnicon}
                  alt="loading..."
                  style={{ width: "50px", height: "50px" }}
                />
              </div>

              <div className="poppins-black text-center pt-2">
                Appliance Installation
              </div>
              <div className="poppins-regular text-center  pt-1">
                We'll ensure your appliances are properly installed and ready to
                use.
              </div>
            </div>
            <div className="col-md-4 mt-3" onClick={handleShow}>
              <div
                onClick={handleShow}
                className="d-flex"
                style={{ justifyContent: "center" }}
              >
                <img
                  src={vnicon2}
                  alt="loading..."
                  style={{ width: "50px", height: "50px" }}
                />
              </div>

              <div className="poppins-black text-center pt-2">
                Electrical Work
              </div>
              <div className="poppins-regular text-center  pt-1">
                Our licensed electricians can handle all your electrical needs.
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className="row mb-3">
            <div className="col-md-2"></div>
            <div className="col-md-4 mt-3" onClick={handleShow}>
              <div
                onClick={handleShow}
                className="d-flex"
                style={{ justifyContent: "center" }}
              >
                <img
                  src={vnicon2}
                  alt="loading..."
                  style={{ width: "50px", height: "50px" }}
                />
              </div>

              <div className="poppins-black text-center pt-2">
                Carpentry Services
              </div>
              <div className="poppins-regular text-center  pt-1">
                From furniture assembly to minor repairs, our carpenters are
                skilled professionals.
              </div>
            </div>
            <div className="col-md-4 mt-3" onClick={handleShow}>
              <div
                onClick={handleShow}
                className="d-flex"
                style={{ justifyContent: "center" }}
              >
                <img
                  src={vnicon3}
                  alt="loading..."
                  style={{ width: "50px", height: "50px" }}
                />
              </div>

              <div className="poppins-black text-center pt-2">
                Painting Services
              </div>
              <div className="poppins-regular text-center  pt-1">
                Freshen up your new space with our professional painting
                services.
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className="row mb-3" style={{ justifyContent: "center" }}>
            <div className="col-md-2"></div>
            <div className="col-md-4 mt-3" onClick={handleShow}>
              <div
                onClick={handleShow}
                className="d-flex"
                style={{ justifyContent: "center" }}
              >
                <img
                  src={vnicon2}
                  alt="loading..."
                  style={{ width: "50px", height: "50px" }}
                />
              </div>

              <div className="poppins-black text-center pt-2">
                Plumbing Services
              </div>
              <div className="poppins-regular text-center  pt-1">
                We can handle any plumbing issues, from leaks to installations.
              </div>
            </div>
            <div className="col-md-4 mt-3" onClick={handleShow}>
              <div
                onClick={handleShow}
                className="d-flex"
                style={{ justifyContent: "center" }}
              >
                <img
                  src={vnicon2}
                  alt="loading..."
                  style={{ width: "50px", height: "50px" }}
                />
              </div>

              <div className="poppins-black text-center pt-2">
                Rope Pulling Services
              </div>
              <div className="poppins-regular text-center  pt-1">
                Need to move heavy items through tight spaces? Our rope pulling
                experts can help.
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>

          {/*Second Module Completed  */}
          {/* Third Module Start */}
          <div className="row mt-5 mb-3">
            <div className="poppins-semibold text-center">
              SOME WORDS OF OUR HAPPY CUSTOMER
            </div>

            <div className="col-md-6 mt-5">
              <div className="poppins-regular" style={{ color: "black" }}>
                <span className="poppins-black">Meena Sharma - </span>
                Moving from Delhi to Chennai
              </div>

              <div className="poppins-regular-italic pt-3">
                "I was so grateful to find Vijay Home Services when I needed to
                move from Delhi to Chennai. They were very accommodating and
                flexible with my schedule. The movers were polite and helpful,
                and they packed everything securely. My belongings arrived
                safely and on time. I was so happy with their service."
              </div>
            </div>

            <div className="col-md-6 mt-5">
              <div className="poppins-regular" style={{ color: "black" }}>
                <span className="poppins-black">Rakesh Patel - </span>
                Rakesh Patel - Moving from Bengaluru to Mumbai
              </div>

              <div className="poppins-regular-italic pt-3">
                "I was extremely nervous about moving from Bengaluru to Mumbai,
                but Vijay Home Services made the entire process stress-free.
                Their team was professional, efficient, and very careful with my
                belongings. I was impressed by their packaging techniques and
                the speed of the move. I would highly recommend them to anyone
                looking for reliable packers and movers."
              </div>
            </div>

            <div className="col-md-6 mt-5">
              <div className="poppins-regular" style={{ color: "black" }}>
                <span className="poppins-black">Rakesh Patel - </span>
                Arijit Roy - Moving from Kolkata to Hyderabad
              </div>

              <div className="poppins-regular-italic pt-3">
                "I had a very positive experience with Vijay Home Services. They
                provided a detailed quote upfront and there were no hidden
                charges. The movers were experienced and knew how to handle my
                fragile items with care. I was impressed by their customer
                service and would definitely use them again."
              </div>
            </div>

            <div className="col-md-6 mt-5">
              <div className="poppins-regular" style={{ color: "black" }}>
                <span className="poppins-black">Rakesh Patel - </span>
                Kavita Joshi - Moving from Pune to Jaipur
              </div>

              <div className="poppins-regular-italic pt-3">
                "I was initially hesitant about hiring packers and movers, but
                I'm so glad I chose Vijay Home Services. They took care of
                everything from packing to unpacking, and the entire process was
                smooth and efficient. The movers were very friendly and
                professional. I would definitely recommend them to anyone moving
                from Pune to Jaipur."
              </div>
            </div>
          </div>

          <div className="row mt-5">
  <div className="poppins-extrabold">House Shifting Services Near You</div>
  <div className="poppins-black mt-3 mb-2">Serviceable areas in Bangalore</div>

  <div>
    <ul className="row pt-2" style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
      {[
        "Whitefield", "Indira Nagar", "HSR Layout", "JP Nagar",
        "Electronic City", "BTM Layout", "Rajajinagar", "Yelahanka",
        "Nagarbhavi", "Bommanahalli", "Marathahalli", "KR Puram",
        "Sarjapur Road", "Banashankari", "Kadugodi", "Mahadevapura",
        "Shivajinagar", "Bellandur", "Hebbal", "Jayanagar",
      ].map((area, index) => (
        <li key={index} className="poppins-regular" style={{ flex: "1 1 22%", textAlign: "justify" }}>
          <a href="https://www.vijayhomeservices.com/packers-movers/bangalore">
            Packers and Movers in {area}
          </a>
        </li>
      ))}
    </ul>
  </div>

  <div className="poppins-black mt-3 mb-2">Now in more cities</div>
  <div>
    <ul className="row pt-2" style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
      {[
        "Ahmedabad", "Bhubaneshwar", "Delhi", "Ghaziabad",
        "Hyderabad", "Kolkata", "Mumbai", "NCR",
        "Patna", "Surat", "Vizag", "Bangalore",
        "Chennai", "Faridabad", "Indore", "Mysore",
        "Kochi", "Gurugram", "Lucknow", "Noida",
        "Pune", "Vadodara"
      ].map((city, index) => (
        <li key={index} className="poppins-regular" style={{ flex: "1 1 22%", textAlign: "justify" }}>
          <a href={`https://www.vijayhomeservices.com/packers-movers/${city.toLowerCase()}`}>
            Packers and Movers in {city}
          </a>
        </li>
      ))}
    </ul>
  </div>

  <div className="poppins-black mt-3 mb-2">VHS Most Booking Routes</div>
  <div>
    <ul className="row pt-2" style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
      {[
        { from: "Bangalore", to: "Chennai" },
        { from: "Bangalore", to: "Delhi" },
        { from: "Bangalore", to: "Hyderabad" },
        { from: "Bangalore", to: "Kolkata" },
        { from: "Bangalore", to: "Mumbai" },
        { from: "Bangalore", to: "Pune" },
        { from: "Bangalore", to: "Ahmedabad" },
        { from: "Bangalore", to: "Kochi" }
      ].map((route, index) => (
        <li
        key={index}
        className="poppins-regular"
        style={{
          flex: "0 0 calc(33.33% - 20px)", // Fixed width for 3 items per line
          textAlign: "justify",
          boxSizing: "border-box", // Ensure padding and border are included in the width
        }}
      >
          <a href={`https://www.vijayhomeservices.com/packers-movers/${route.from.toLowerCase()}`}>
            Packers and Movers From {route.from} to {route.to}
          </a>
        </li>
      ))}
    </ul>
  </div>
</div>

<div className="container mt-5">
  {/* Heading */}
  <div className="custom-subheading mt-3 mb-2" style={{ fontSize: "1.4rem", color: "darkred" }}>
    Best Packers and Movers in {formattedCity}
  </div>

  {/* Introduction Paragraph */}
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    The hustling city of {formattedCity} attracts thousands of IT professionals throughout the year. Choosing efficient packers and movers in {formattedCity} becomes a necessity for these working professionals. For seamless and quick residential and commercial relocations, Vijay Home Services is here to help you. We are noted for our reliability and for executing high-quality relocations. We have a team of highly skilled professionals who are experts in providing customised moving plans that suit your unique needs. On our user-friendly platform, you can choose from a wide range of moving services and get price quotations instantly. Our transparent pricing, premium packing, and flexible moving options enable us to establish trust among our customers. Choose Vijay Home Services and enjoy a pleasant and hassle-free moving experience.
  </div>

  {/* Services Offered Section */}
  <div className="poppins-black mt-4 mb-3" style={{ fontSize: "1.5rem", color: "darkred" }}>
    What are the Different Services offered by Vijay Home Services?
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    At Vijay Home Services, we offer a wide range of packers and movers services in {formattedCity}. From residential moves to commercial relocations and city tempo services, our experts are proficient in handling every type and aspect of moving. We have a specialised team to oversee different services, resulting in a safe and efficient moving experience. Following are some of the best relocation services offered by Vijay Home Services movers and packers in {formattedCity}.
  </div>

  {/* House Shifting Services */}
  <div className="custom-subheading mt-3 mb-2">
    House Shifting Services:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Vijay Home Services is one of the best options for home-shifting services in {formattedCity}. Our professional workforce is capable of carefully loading, packing, moving, and organising your home with the highest care and convenience. We use only high-quality packing material, which ensures the protection of your belongings during transit. We are also versatile in terms of post-shifting services such as product reassembly and appliance installation.
  </div>

  {/* Car Transport Services */}
  <div className="custom-subheading mt-3 mb-2">
    Car Transport Services:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    If you are moving far within the city or moving to another city that is far from your current location, it is only logical to hire a car relocator. For car transportation services in {formattedCity}, Vijay Home Services is a well-known brand. Our flexible booking policy is ideal for transferring vehicles from one place to another on a timeline that is convenient for you. Our team of specialists will carefully pack and move your car, ensuring that it arrives at the destination on schedule.
  </div>

  {/* Bike Shifting Services */}
  <div className="custom-subheading mt-3 mb-2">
    Bike Shifting Services:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    For shifting a bike, one must have ample experience. At Vijay Home Services, our workers are well-known for providing excellent bike-shifting services in {formattedCity}. Our team of professionals are very competent in packaging and transporting expensive sports motorcycles to your next adventure destination. With a commitment to providing excellent service and the use of high-grade materials, we ensure that your favourite bike is delivered scratch-free and on schedule.
  </div>

  {/* City Tempo Services */}
  <div className="custom-subheading mt-3 mb-2">
    City Tempo Services:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    City tempo service in {formattedCity} is an excellent option for transporting fewer items. In addition, it is less expensive than renting a fully functional vehicle and can handle {formattedCity}’s traffic well. We can help with your relocations with its well-maintained fleet of tempo travellers, which includes Chota Hathi and TATA Ace. Contact Vijay Home Services for a smooth and affordable shifting experience.
  </div>

  {/* Office Shifting Services */}
  <div className="custom-subheading mt-3 mb-2">
    Office Shifting Services:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Office moves typically cause a pause in regular business operations. The relocation procedure needs to be quick and effective. We provide the most efficient and speedy office-shifting services in {formattedCity}. Before transferring everything to your new workplace, the team of professionals carefully packs all of the bulky equipment, furniture, laptops, servers, and documents. Upon reaching the new workplace, we will also unpack and organise the place before leaving and we also make sure your business is not affected during the move.
  </div>

  {/* Booking Section */}
  <div className="poppins-black mt-4 mb-3" style={{ fontSize: "1.5rem", color: "darkred" }}>
    How to Book Vijay Home Services Packers and Movers Near {formattedCity}?
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    With our carefully designed platform, we have simplified the process of booking. We provide a convenient booking experience for everyone. With numerous moving options, transparent pricing, and live chat support options, our booking website is made to provide you with everything under one platform. Our commitment to excel and offer premium quality services places us among the top packers and movers in {formattedCity}. Here is a guide to booking home shifting in {formattedCity} via Vijay Home Services – for both local and intercity moves!
  </div>

  {/* Local Home Shifting */}
  <div className="custom-subheading mt-3 mb-2">
    Local Home Shifting:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Following are the steps to book your local home shift near {formattedCity}.
    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
      <li>Visit Website/ App: Access Vijay Home Services’s app or website for your booking.</li>
      <li>Select your Shifting Location: Enter the elements of your move, such as your current locality and new address in {formattedCity}.</li>
      <li>Add Inventory: Enter all the articles you expect us to move.</li>
      <li>Enter Shifting Date and Time: Select the date and time for shifting.</li>
      <li>Review Details and Confirm: Go through the points to make sure everything is clear.</li>
      <li>Make Online Payment: To conclude the booking process, make the payment via online transaction.</li>
    </ul>
  </div>

  {/* Intercity House Shifting */}
  <div className="custom-subheading mt-3 mb-2">
    Intercity House Shifting:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Here’s your guide to intercity home services in {formattedCity}.
    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
      <li>Visit Website/ App: Start by visiting our website or mobile application for your booking.</li>
      <li>Select your Shifting Location: Choose your current locality and destination.</li>
      <li>Add Inventory: Provide the listing of every item you are preparing to move.</li>
      <li>Enter Shifting Date and Time: Pick the date and time slot for moving.</li>
      <li>Review Details and Confirm: Carefully examine all the details and press confirm.</li>
      <li>Make Online Payment: Pay online, and your booking is confirmed.</li>
    </ul>
  </div>

  {/* Why Vijay Home Services Section */}
  <div className="poppins-black mt-4 mb-3" style={{ fontSize: "1.5rem", color: "darkred" }}>
    Why Vijay Home Services’s Packers and Movers in {formattedCity}?
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    We are dedicated to offering our clients the most outstanding moving experience possible, guaranteeing a smooth and stress-free move. Our skilled professionals, together with efficient operations, provide a pleasant moving experience at all stages. The positive feedback that we regularly receive from our clients makes us evolve constantly. Let us examine some of the features that set Vijay Home Services Packers and Movers in {formattedCity} apart from the rest of the relocation providers.
  </div>

  {/* Lowest Price Guarantee */}
  <div className="custom-subheading mt-3 mb-2">
    Lowest Price Guarantee:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    We take pride in our lowest price guarantee policy. Our professionals have created a platform that provides instant and accurate pricing. It ensures that there are no hidden charges or unexpected fee additions at the last minute. The process is entirely free from intermediaries, which helps eliminate unnecessary costs. Our honesty and transparency in the pricing mechanism have gained us immense customer support.
  </div>

  {/* Best Quality Service */}
  <div className="custom-subheading mt-3 mb-2">
    Best Quality Service:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    At Vijay Home Services, we put quality over everything else. Therefore, we offer premium packing material for every booking. Our skilled labourers execute each booking with the same level of dedication so that you receive the same high standard of quality service. Using premium-quality packaging and efficient handling ensures the protection of your goods throughout the relocation process.
  </div>

  {/* Reschedule Your Shifting Anytime */}
  <div className="custom-subheading mt-3 mb-2">
    Reschedule Your Shifting Anytime:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    At Vijay Home Services, we understand how difficult it is to maintain a work-life balance. Your moving plans can change due to last-minute meetings or urgent business trips. Therefore, we offer our customers the option to reschedule their booking anytime. You can also avail of the free rescheduling option if the booking date is altered 48 hours before the scheduled booking date.
  </div>

  {/* Support Assistance */}
  <div className="custom-subheading mt-3 mb-2">
    Support Assistance:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    When moving feels overwhelming, you can reach out to our dedicated customer support team. Whether you need to clear your moving-related doubts or calm your anxiety, our representatives are well-trained to handle every situation. We are available 24/7 on the live chat support option, which you can access through our website or mobile application. Our commitment to customer satisfaction is evident in the quality of support we provide.
  </div>

  {/* Professional Labour */}
  <div className="custom-subheading mt-3 mb-2">
    Professional Labour:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Our ground crew is the backbone of our every moving operation. They are hired by thorough vetting and trained to provide flawless service at every step of the process. We have specialists for every type of move, including car, residential, commercial, motorcycle and tempo services. Our proficient team handles each step of the move with professionalism and precision. Extra care is provided for delicate and valuable items. Moreover, our crew is skilled at many post-shift services as well such as setting up appliances, reassembly of furniture and setting up the house.
  </div>

  {/* Process Section */}
  <div className="poppins-black mt-4 mb-3" style={{ fontSize: "1.5rem", color: "darkred" }}>
    What is the Process for Vijay Home Services Home Shifting Services in {formattedCity}?
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    We have a simplified home and commercial relocation booking process that enables you to book your shift with just a few clicks. All you need to do is enter the required information, and we will give you a price quote. After you make the payment, our professionals will handle the rest while you sit back and relax. We have streamlined the entire process, and our workers are punctual at every step of the booking. Let us guide you through the steps involved when executing a home relocation service via Vijay Home Services Packers and Movers in {formattedCity}.
  </div>

  {/* Booking Process */}
  <div className="custom-subheading mt-3 mb-2">
    Booking:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    The first part of the booking process starts when you visit our platform and confirm the booking by paying the token amount. You will have to enter the information asked on the website so that we can determine the total cost of moving and make provisions for the move. Proceed by entering information such as Name, current location, destination, preferred date and time of driving, volume of goods, etc. If you have any speciality items such as artwork, antiques or pianos, remember to specify them. Make the payment online and schedule your move.
  </div>

  {/* Packaging Process */}
  <div className="custom-subheading mt-3 mb-2">
    Packaging:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    On the determined date, our professional team of packers and movers will visit your current location and begin packing your belongings. Our team is well aware of the modern packing methods and will ensure the safety and security of your articles. They will pack each item in premium and high-grade packing material for the utmost protection of your belongings during transportation. Fragile items, such as glassware, electronics, etc, will be packed in a double layer of bubble wrap and stored in boxes filled with packing peanuts.
  </div>

  {/* Transport Process */}
  <div className="custom-subheading mt-3 mb-2">
    Transport:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Once everything is packed, our ground crew will carefully load the items on the vehicle for transportation. We have a large fleet of automobiles ranging from chota hathi to heavy-duty trucks; facilitating the move according to the volume of items. The vehicles are regularly serviced and driven by efficient drivers who are well aware of the routes in {formattedCity}. Our team’s efficiency and experience eliminate any chances of delay or damage during the move.
  </div>

  {/* Unloading Process */}
  <div className="custom-subheading mt-3 mb-2">
    Unloading:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Upon reaching the destination, our workers will safely unload the items and manually move them to your new house. Our workers know how tricky it can be to unload delicate items, so they handle it with extra caution. We have a well-trained team of experts who will move the items without stress and hassle for you.
  </div>

  {/* Unpacking and Furniture Assembly */}
  <div className="custom-subheading mt-3 mb-2">
    Unpacking and Furniture Assembly:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    We will begin unpacking the items as soon as they arrive in your new house. This includes carefully removing each item from its box, reassembling the furniture, and arranging larger items such as mattresses, wardrobes, and sofas as per your directions. Our team pays keen attention to detail and will follow your instructions to make sure everything is set up exactly as you desire.
  </div>

  {/* Final Inspection */}
  <div className="custom-subheading mt-3 mb-2">
    Final Inspection:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    After everything has been unpacked and put in place, the team will do a last examination of your belongings. Our team of experts will determine whether any goods were damaged during the move and make sure that everything is working correctly. We strive to make the entire shifting process stress-free and pleasant, ensuring that your items arrive safely and securely at your new home.
  </div>

  {/* Pricing Section */}
  <div className="poppins-black mt-4 mb-3" style={{ fontSize: "1.5rem", color: "darkred" }}>
    Vijay Home Services’s Packers and Movers Charges in {formattedCity}
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Vijay Home Services is well-known for offering a variety of services at low prices. Let’s look at the packers and movers prices in {formattedCity} for both local and intercity moves.
  </div>

  {/* Local House Shifting Charges */}
  <div className="custom-subheading mt-3 mb-2">
    Local House Shifting Charges in {formattedCity}:
  </div>
  <table className="table table-bordered">
    <thead>
      <tr>
        <th>BHK Type</th>
        <th>Starting Price</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1 BHK</td>
        <td>₹4,999</td>
      </tr>
      <tr>
        <td>2 BHK</td>
        <td>₹7,999</td>
      </tr>
      <tr>
        <td>3 BHK</td>
        <td>₹11,999</td>
      </tr>
    </tbody>
  </table>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "0.9rem", fontStyle: "italic" }}>
    Disclaimer: Note that the cost may vary depending on the number of items to be moved, the distance, the season, and the day (e.g., a weekday, a weekend, a month-end). You are advised to contact Vijay Home Services Packers and Movers directly.
  </div>

  {/* Intercity House Shifting Charges */}
  <div className="custom-subheading mt-3 mb-2">
    Intercity House Shifting Charges in {formattedCity}:
  </div>
  <table className="table table-bordered">
    <thead>
      <tr>
        <th>BHK Type</th>
        <th>Starting Price</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1 BHK</td>
        <td>₹14,999</td>
      </tr>
      <tr>
        <td>2 BHK</td>
        <td>₹19,999</td>
      </tr>
      <tr>
        <td>3 BHK</td>
        <td>₹24,999</td>
      </tr>
    </tbody>
  </table>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "0.9rem", fontStyle: "italic" }}>
    Disclaimer: Note that the cost may vary depending on the number of items to be moved, the distance, the season, and the day (e.g., a weekday, a weekend, a month-end). You are advised to contact Vijay Home Services Packers and Movers directly.
  </div>

  {/* Intercity Vehicle Shifting Charges */}
  <div className="custom-subheading mt-3 mb-2">
    Intercity Vehicle Shifting Charges in {formattedCity}:
  </div>
  <table className="table table-bordered">
    <thead>
      <tr>
        <th>Type of Vehicle</th>
        <th>Starting Price</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Bike</td>
        <td>₹4,999</td>
      </tr>
      <tr>
        <td>Car</td>
        <td>₹9,999</td>
      </tr>
    </tbody>
  </table>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "0.9rem", fontStyle: "italic" }}>
    Disclaimer: Note that the cost may vary depending on the number of items to be moved, the distance, the season, and the day (e.g., a weekday, a weekend, a month-end). You are advised to contact Vijay Home Services Packers and Movers directly.
  </div>

  {/* Factors Affecting Moving Costs */}
  <div className="poppins-black mt-4 mb-3" style={{ fontSize: "1.5rem", color: "darkred" }}>
    Factors Affecting Moving Costs in {formattedCity}
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    When we say Vijay Home Services guarantees the lowest price, we mean that we will strive to provide you with the best deal possible. Certain factors influence the price quote, while others do not. To achieve maximum transparency, we maintain our pricing system without any human intervention. However, a variety of reasons might raise the overall cost of relocation. Let us look at these factors:
  </div>

  {/* Season and Timing */}
  <div className="custom-subheading mt-3 mb-2">
    Season and Timing:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
      <li>Weekdays vs. Weekends: The heightened demand on weekends and holidays makes moving more costly.</li>
      <li>Month-end vs. Mid-month: Moving on month-end can be expensive as rental agreements terminate on that day.</li>
      <li>Peak Season vs. Off-peak Season: Peak season has increased demand, leading to a rise in fees.</li>
    </ul>
  </div>

  {/* Distance */}
  <div className="custom-subheading mt-3 mb-2">
    Distance:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Intercity shifts are pricier than local shifts due to lengthier trips, fuel prices, and taxes. Intercity travel requires the driver to operate for a more extended period, which adds to the expense.
  </div>

  {/* Volume and Weight of Items */}
  <div className="custom-subheading mt-3 mb-2">
    Volume and Weight of Items:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Let’s say there are too many or too heavy objects. In that instance, they will need additional labour, packaging materials, and a larger vehicle, which may result in higher expenses.
  </div>

  {/* Type of Items */}
  <div className="custom-subheading mt-3 mb-2">
    Type of Items:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Fragile and heavy objects need special care and extra packing material. Valuables such as musical instruments or artwork are transported with extreme care.
  </div>

  {/* Taxes and Fees */}
  <div className="custom-subheading mt-3 mb-2">
    Taxes and Fees:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Toll taxes and regulatory fees vary by state. As a result, the pricing will vary depending on the location.
  </div>

  {/* Packaging Material */}
  <div className="custom-subheading mt-3 mb-2">
    Packaging Material:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    High-quality materials might drive up the cost, especially if you’re carrying sensitive items, artwork or speciality items.
  </div>

  {/* Accessibility */}
  <div className="custom-subheading mt-3 mb-2">
    Accessibility:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Relocating items to flats without elevators or extensive corridors might raise the overall cost of relocating.
  </div>

  {/* Vehicle Parking to New Home Distance */}
  <div className="custom-subheading mt-3 mb-2">
    Vehicle Parking to New Home Distance:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    If the parking is far away from your house, the personnel will have to transport the things personally. This will increase the cost.
  </div>

  {/* Urgent or Last-Minute Moves */}
  <div className="custom-subheading mt-3 mb-2">
    Urgent or Last-Minute Moves:
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Last-minute or urgent relocation typically results in increased charges since moving companies must reschedule and assign additional crew. The extra manpower and supplies required for a speedy move typically result in higher overall prices.
  </div>

  {/* Packaging Materials Section */}
  <div className="poppins-black mt-4 mb-3" style={{ fontSize: "1.5rem", color: "darkred" }}>
    What Packaging Materials are used in Vijay Home Services’s Packers and Movers Near You?
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    Vijay Home Services Packers and Movers are known for their high-quality, durable packing. There is a heavy emphasis on packaging to avoid accidents or damage to objects throughout the relocation process. Let us take a look at some of the top packing materials used by Vijay Home Services.
  </div>
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>Bubble Wrap: Bubble wraps prevent goods from shattering by providing cushioning on the surface.</li>
    <li>Cardboard Boxes: These are our top choices for packing. They are long-lasting and come in a variety of sizes to suit different objects. Items too tiny to ship separately, such as garments, are packaged in robust and reusable polypropylene boxes.</li>
    <li>Packing peanuts: They prevent breakage by occupying the empty areas in the boxes and securing the items.</li>
    <li>Packing tape: Heavy-duty tape with a strong adhesive is used to pack the boxes and provide extra protection for the objects wrapped in bubble wrap.</li>
    <li>Stretch Wrap: When moving, large objects like furniture are shielded by stretch wrap.</li>
  </ul>

  {/* Conclusion */}
  <div className="poppins-black mt-4 mb-3" style={{ fontSize: "1.5rem", color: "darkred" }}>
    Conclusion
  </div>
  <div className="poppins-regular text-justify mb-4" style={{ fontSize: "1rem", lineHeight: "1.6" }}>
    To sum up, Vijay Home Services is the best packer and mover in {formattedCity}. We are known for our reliable and efficient moving services, which are affordable. We are an excellent choice for a hassle-free moving experience. Our team of experts is experienced and well-trained in every aspect of relocating. The customer support team is well aware of the delays and troubles that may occur during the move and can handle them efficiently. For our wide variety of moving services, including home, office and vehicle shifting, we offer transparent and instant pricing that establishes trust among our customers. Making a hassle-free journey for you is the goal of Vijay Home Services packers and movers in {formattedCity}. Book us for your upcoming move!
  </div>
</div>


          <div className="row mt-5" style={{ justifyContent: "center" }}>
            <div className="poppins-black text-center">
              Frequently Asked Questions
            </div>

            <div className="col-md-10 mt-4 mb-4">
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="poppins-regular">
                    What services do you offer as part of your Packers and
                    Movers?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Weoffer end-to-end services including packing, loading,
                    transporting, unloading, and unpacking of household and
                    office goods.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="poppins-regular">
                    How do you ensure the safety of fragile and valuable items?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Weuse high-quality packing materials such as bubble wrap,
                    cartons, and foam to secure fragile items. Our team is
                    trained to handle delicate items with care.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="poppins-regular">
                    Do you provide door-to-door relocation services?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Yes, we provide complete door-to-door services to ensure a
                    hassle-free relocation experience.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="poppins-regular">
                    Are there any hidden charges in your pricing?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    No, our pricing is transparent, and we provide detailed
                    quotes upfront. There are no hidden fees.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="poppins-regular">
                    What areas do you serve?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Weprovide packers and movers services across 21+ cities in
                    India, covering most major metropolitan areas.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header className="poppins-regular">
                    Can I reschedule my moving date?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Yes, we offer flexible scheduling. You can reschedule your
                    move by contacting our support team in advance.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header className="poppins-regular">
                    What if my goods get damaged during transit?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Wetake all precautions to prevent damage, but in the rare
                    case of damage, we offer insurance coverage to compensate
                    for any losses.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header className="poppins-regular">
                    How do you determine the cost of the move?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    The cost is determined by factors such as the volume of
                    goods, distance, type of services required
                    (packing/unpacking), and any additional requests like
                    insurance or special handling.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header className="poppins-regular">
                    Do you offer insurance for the move?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Yes, we provide optional insurance coverage to protect your
                    belongings during transit.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header className="poppins-regular">
                    How do I book your services?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    You can book our services by contacting us via phone, email,
                    or through our website. A booking confirmation will be
                    provided once details are finalised.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header className="poppins-regular">
                    How do I book your services?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    You can book our services by contacting us via phone, email,
                    or through our website. A booking confirmation will be
                    provided once details are finalised.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header className="poppins-regular">
                    Do you provide transportation for vehicles like cars or
                    bikes?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Yes, we offer specialised vehicle transportation services
                    for cars and bikes.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header className="poppins-regular">
                    How early should I book my move?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Werecommend booking at least a week in advance to ensure
                    availability, especially during peak moving seasons.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header className="poppins-regular">
                    How long does the moving process take?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    The time depends on factors like the distance of the move
                    and the volume of goods. For local moves, it typically takes
                    a day, while long-distance moves may take a few days.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <Accordion.Header className="poppins-regular">
                    Can you handle international relocations?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    At the moment, we specialize in domestic relocations within
                    India.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                  <Accordion.Header className="poppins-regular">
                    What items should I avoid packing during the move?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Avoid packing hazardous materials like flammable liquids,
                    explosives, and perishables like food items, as they may not
                    be safe for transportation.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                  <Accordion.Header className="poppins-regular">
                    Can I track my shipment during the move?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Yes, we offer tracking services so that you can monitor the
                    status of your shipment during transit.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="17">
                  <Accordion.Header className="poppins-regular">
                    Do you offer storage services if I need to temporarily store
                    my goods?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Yes, we provide secure and reliable short-term and long-term
                    storage facilities if needed.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="18">
                  <Accordion.Header className="poppins-regular">
                    What kind of vehicles do you use for transporting goods?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Wehave a fleet of vehicles ranging from small trucks for
                    local moves to large trucks for long-distance relocations,
                    ensuring the right vehicle for your needs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="19">
                  <Accordion.Header className="poppins-regular">
                    Do I need to be present during the packing and moving
                    process?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    It is preferable for you or a representative to be present
                    to supervise and provide guidance, but we can manage the
                    process independently if needed.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="20">
                  <Accordion.Header className="poppins-regular">
                    How do I prepare for my move?
                  </Accordion.Header>
                  <Accordion.Body className="poppins-light">
                    Werecommend decluttering, labelling boxes, and informing us
                    of any special requests ahead of time to ensure a smooth and
                    efficient move.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

          
          </div>

          <div className="pm-mobile mb-5">
            <div className="poppins-black">How we Work</div>
            <img src={howwework} alt="loading...." style={{ width: "100%" }} />
          </div>

          {/* Third Completed */}
        </div>

        <Modal centered show={show} onHide={handleClose}>
          <Modal.Body>
            <div className="poppins-semibold">Enquiry Add</div>

            <div className="mt-2">
              <div className="poppins-light">Name</div>
              <input
                type="text"
                className="input col-md-12 mt-2 vhs-input-value"
                value={customer}
                onChange={(e) => setcustomer(e.target.value)}
              />
            </div>

            <div className="">
              <div className="poppins-light">Contact Number</div>
              <input
                type="text"
                value={mobilenumber}
                className="input col-md-12 mt-2 vhs-input-value"
                onChange={(e) => setMobilenumber(e.target.value)}
              />
            </div>

            <div className="">
              <div className="poppins-light">Category</div>
              <select
                value={selectcategory}
                onChange={(e) => setselectcategory(e.target.value)}
                className="input col-md-12 mt-2 vhs-input-value"
              >
                <option>--select category--</option>
                <option>Appliance Installation</option>
                <option>Electrical Work</option>
                <option>Carpentry Services</option>
                <option>Painting Services</option>
                <option>Plumbing Services</option>
                <option>Rope Pulling Services</option>
              </select>
            </div>

            <div
              onClick={addEnquiry}
              className="poppins-black"
              style={{
                backgroundColor: "darkred",
                padding: "7px",
                borderRadius: "5px",
                marginTop: "20px",
                color: "white",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              Submit
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={openResetModal}
          style={{ width: "100%" }}
          centered
          onHide={handleResetModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="web-city-modal">
            <div className="modal_grid ">
              <div className="modal_header">
                <img
                  src={cityimg}
                  alt="city images"
                  style={{ width: "100%", height: "175px" }}
                />
              </div>

              <div className="modal_body p-3">
                <div
                  className="title poppins-semibold"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    paddingBottom: "20px",
                  }}
                >
                  <span>
                    <img
                    src={indiaflg}
                      alt="loading..."
                      style={{
                        width: "23px",
                        height: "23px",
                        marginRight: "10px",
                        borderRadius: "50px",
                      }}
                    />
                  </span>
                  India
                </div>
                <div className="city-grid web-view">
              {city.map((city, index) => {
                const imagePath = cityImages[index % cityImages.length]; // Assign image based on the index
                return (
                  <div
                    className="city-item"
                    key={city._id}
                    onClick={() => handleChange(city)}
                  >
                    <img
                      src={imagePath} // Image assigned based on index
                      alt={city.city}
                      className="city-image"
                    />
                    <p
                      className={`city-name ${
                        activeCity === city.city ? "active" : ""
                      }`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        fontWeight: ["Bangalore", "Chennai", "Pune","Hyderabad", "Mumbai", "Delhi", "Gurugram"].includes(city.city)
            ? "900" // Make these cities bold
            : "normal", // Default for other cities
          color: ["Bangalore", "Chennai", "Pune","Hyderabad", "Mumbai", "Delhi", "Gurugram"].includes(city.city)
            ? "red" // Make these cities bold
            : "black", // Default for other cities
        
                      }}
                    >
                      {city.city}
                    </p>
                  </div>
                );
              })}
            </div>
                <div
                  className="poppins-semibold mt-2"
                  style={{ textAlign: "left" }}
                >
                  Coming Soon
                </div>
                <div
                  className="d-flex mt-2"
                  style={{ justifyContent: "center", gap: "40px" }}
                >
                  <div className="col-md-6">
                    <div className=" poppins-semibold mt-1">
                      <span>
                        <img
                          src={dubai}
                          alt="loading..."
                          style={{
                            width: "23px",
                            height: "23px",
                            marginRight: "10px",
                            borderRadius: "50px",
                          }}
                        />
                      </span>
                      Dubai{" "}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className=" poppins-semibold mt-1">
                      <span>
                        <img
                          src={london}
                          alt="loading..."
                          style={{
                            width: "23px",
                            height: "23px",
                            marginRight: "10px",
                            borderRadius: "50px",
                          }}
                        />
                      </span>
                      London{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-city-modal">
            <div className="modal_grid ">
              <div className="modal_header">
                <img
                 src={cityimg}
                  //src="https://vijayahomeservices.b-cdn.net/city.jpg"
                  alt="city images"
                  className="city_images"
                />
              </div>

              <div className="modal_body p-3">
                <div
                  className="title poppins-semibold"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    paddingBottom: "20px",
                  }}
                >
                  <span>
                    <img
                      src={indiaflg}
                      alt="loading..."
                      style={{
                        width: "23px",
                        height: "23px",
                        marginRight: "10px",
                        borderRadius: "50px",
                      }}
                    />
                  </span>
                  India
                </div>
                <div className="city-grid mobile-view" style={{ paddingLeft: "-1px" }}>
              {city.map((city, index) => (
                <div
                  className="city-item"
                  key={city._id}
                  onClick={() => handleChange(city)}
                >
                  <img
                    src={cityImages[index % cityImages.length]} // Image assigned based on index
                    alt={city.city}
                    className="city-image"
                    style={{
                      width: "50px",
                      height: "50px",
                      marginBottom: "0px",
                      marginTop: "5px",
                    }}
                    onLoad={() => console.log("Image loaded for city:", city.city, "Image:", city.image)} // Logs when the image is loaded
                    onError={() => console.log("Failed to load image for city:", city.city, "Image:", city.image)} // Logs if the image fails to load
                  />
                  <p
        className={`city-name ${activeCity === city.city ? "active" : ""}`}
        style={{
          fontSize: "16px",
          margin: "0px",
          padding: "0px 5px",
          fontWeight: ["Bangalore", "Chennai", "Pune","Hyderabad", "Mumbai", "Delhi", "Gurugram"].includes(city.city)
            ? "900" // Make these cities bold
            : "normal", // Default for other cities
          color: ["Bangalore", "Chennai", "Pune","Hyderabad", "Mumbai", "Delhi", "Gurugram"].includes(city.city)
            ? "red" // Make these cities bold
            : "black", // Default for other cities
        }}
      >
        {city.city}
      </p>
                </div>
              ))}
            </div>
                <div
                  className="poppins-semibold mt-2"
                  style={{ textAlign: "left" }}
                >
                  Coming Soon
                </div>
                <div
                  className="d-flex mt-2"
                  style={{ justifyContent: "center", gap: "40px" }}
                >
                  <div className="col-md-6">
                    <div className=" poppins-semibold mt-1">
                      <span>
                        <img
                          src={dubai}
                          alt="loading..."
                          style={{
                            width: "23px",
                            height: "23px",
                            marginRight: "10px",
                            borderRadius: "50px",
                          }}
                        />
                      </span>
                      Dubai{" "}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className=" poppins-semibold mt-1">
                      <span>
                        <img
                          src={london}
                          alt="loading..."
                          style={{
                            width: "23px",
                            height: "23px",
                            marginRight: "10px",
                            borderRadius: "50px",
                          }}
                        />
                      </span>
                      London{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
 {/* Login Modal */}

 <Modal
          show={showLoginModal}
          centered
          onHide={() => setShowLoginModal(false)} // Close modal
          style={{ borderRadius: "10px" }}
        >
          <Modal.Body
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "30px",
            }}
          >
            <div style={{ width: "100%", maxWidth: "400px" }}>
              <div
                className="poppins-light"
                style={{
                  marginBottom: "10px",
                  fontSize: "16px",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Enter mobile number to continue
              </div>
              {/* <input
                      type="text"
                      value={customername}
                      onChange={(e) => setcustomername(e.target.value)}
                      placeholder="Enter Name"
                      style={{
                        border: "1px solid grey",
                        height: "45px",
                        width: "100%",
                        marginTop: "15px",
                      }}
                    /> */}

              <input
                type="text"
                value={mainContact}
                onChange={(e) => setmainContact(e.target.value)}
                placeholder="Enter Mobile Number"
                style={{
                  border: "1px solid grey",
                  height: "45px",
                  width: "100%",
                }}
              />
              <div
                onClick={sendOTP}
                style={{
                  backgroundColor: "#ff465e",
                  color: "white",
                  textAlign: "center",
                  padding: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {isLoading ? "Loading..." : "Continue"}
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontSize: "14px",
                  color: "#999",
                }}
              >
                Why to choose{" "}
                <span className="poppins-regular" style={{ color: "darkred" }}>
                  Our Services?
                </span>
                <ul
                  style={{
                    listStyle: "none",
                    padding: 0,
                    marginTop: "10px",
                  }}
                >
                  <li
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa fa-check-circle"
                      style={{ color: "green", marginRight: "5px" }}
                    ></i>
                    Lowest Price Guaranteed
                  </li>
                  <li
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa fa-check-circle"
                      style={{ color: "green", marginRight: "5px" }}
                    ></i>
                    Free Reschedule
                  </li>
                  <li
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa fa-check-circle"
                      style={{ color: "green", marginRight: "5px" }}
                    ></i>
                    5 Star Rated Partners
                  </li>
                  <li
                    style={{
                      marginBottom: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fa fa-check-circle"
                      style={{ color: "green", marginRight: "5px" }}
                    ></i>
                    Dedicated Customer Support
                  </li>
                </ul>
              </div>
              {/* <div
                className="poppins-regular"
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  color: "#999",
                  marginTop: "10px",
                }}
              >
                By continuing, you agree to our{" "}
                <a
                  href="/terms-and-condition"
                  className="poppins-regular"
                  style={{ color: "blue" }}
                >
                  Terms & Conditions
                </a>
                and agree to updates on{" "}
                <span className="poppins-regular" style={{ color: "green" }}>
                  WhatsApp
                </span>
                .
              </div> */}
            </div>
          </Modal.Body>
        </Modal>

        <Footer />
      </div>
    </div>
  );
}
export default Packersmovershome;
