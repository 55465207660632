import React from 'react';




const Subcategory_Component = ({ subcategory,scrollToService }) => {
  // Titles and images for each subcategory

  

  // Get the banners for the specific subcategory or an empty array if not found
 
  

  const bathroomCleaning = [
    { title: "Manual Cleaning", image: "/assests/manual_cleaning.jpg" },
    { title: "Machine Cleaning", image: "/assests/machine_cleaning.jpg" },
    { title: "Grouting", image: "/assests/grouting.jpg" },
  ];

  const occupiedHomeCleaning = [
    { title: "Occupied Flats", image: "/assests/occupiedflat.png" },
    { title: "Ocupied Villa", image: "/assests/occupiedvilla.png" },
    
  ];

  const vacantHomeCleaning = [
    { title: "Vacant Flats", image: "/assests/vacantflat.png" },
    { title: "Vacant Villa", image: "/assests/vacantvilla.png" },
    
  ];

  const kitchenCleaning = [
    { title: "Without Cabinet", image: "/assests/withoutcabinet.png" },
    { title: "Vacant Kitchen", image: "/assests/vacantkitchen.png" },
    { title: "Occupied Kitchen", image: "/assests/occupiedkitchen.png" },
    
  ];
  const sofaCleaning = [
    { title: "Fabric Sofa Cleaning", image: "/assests/fabricsofa.png" },
    { title: "Leather Sofa Cleaning", image: "/assests/leathersofa.png" },
    
    
  ];
  const afterinteriorCleaning = [
    { title: "Flat Project Cleaning", image: "/assests/flatprojectcleaning.png" },
    { title: "Duplex Project Cleaning", image: "/assests/duplexprojectcleaning.png" },
    { title: "Villa Project Cleaning", image: "/assests/villaprojectcleaning.png" },
    
  ];
  const officeCleaning = [
    { title: "Office Carpet Cleaning", image: "/assests/officecarpetcleaning.png" },
    { title: "Vacant Office Cleaning", image: "/assests/vacantofficecleaning.png" },
    { title: "Occupied Office Cleaning", image: "/assests/occupiedofficecleaning.png" },
    
  ];
  const mattressCleaning = [
    { title: "Carpet Cleaning", image: "/assests/carpetcleaning.png" },
    { title: "Matress Cleaning", image: "/assests/mattresscleaning.png" },
    { title: "Dining Chair Cleaning", image: "/assests/diningchaircleaning.png" },
    
  ];

  const miniCleaning = [
    { title: "Kitchen Appliance Cleaning", image: "/assests/kitchenappliancecleaning.png" },
    { title: "Other Appliance Cleaning", image: "/assests/otherappliancecleaning.png" },
    
    
  ];
  const floorCleaning = [
    { title: "Floor Cleaning - Manual", image: "/assests/floormanual.png" },
    { title: "Floor Cleaning - Machine", image: "/assests/floormachine.png" },
   ];

   const terraceCleaning = [
    { title: "Terrace Cleaning", image: "/assests/terrace.png" },
    
  ];

  const tankCleaning = [
    { title: "Tank Cleaning", image: "/assests/tankcleaning.png" },
    { title: "Sump Cleaning", image: "/assests/sumpcleaning.png" },
    
  ];

  const cockroachControl = [
    { title: "Cockroach Control - Standard", image: "/assests/cockroachstandard.png" },
    { title: "Cockroach Control - Premium", image: "/assests/cockroachpremium.png" },
    
  ];

  const generalControl = [
    { title: "General Pest Control Standard", image: "/assests/generalpest.png" },
    { title: "General Pest Control AMC", image: "/assests/generalpestamc.png" },
    
  ];
  const bedbugsControl = [
    { title: "Bedbugs ", image: "/assests/generalpest.png" },
    { title: "General Pest Control AMC", image: "/assests/generalpestamc.png" },
    
  ];

  const mosquitoControl = [
    { title: "Mosquito Control - Indoor", image: "/assests/mosquitoindoor.png" },
    { title: "Mosquito Control - Outdoor", image: "/assests/mosquitooutdoor.png" },
    
  ];
  const termiteControl = [
    { title: " Termite Control  (Post construction)", image: "/assests/termitepost.png" },
    { title: " Termite Control  (Pre-construction)", image: "/assests/termitepre.png" },
    
  ];
  const woodborerControl = [
    { title: "Wood Borer - Standard", image: "/assests/woodborerstandard.png" },
    { title: "Wood Borer - Premium", image: "/assests/woodborerpremium.png" },
    
  ];





  // Determine which titles and images to render based on the subcategory
  let titlesAndImages = [];
  if (subcategory.toLowerCase().includes("bathroom")) {
    titlesAndImages = bathroomCleaning;
  } else if (subcategory.toLowerCase().includes("occupied")) {
    titlesAndImages = occupiedHomeCleaning;
  } else if (subcategory.toLowerCase().includes("vacant")) {
    titlesAndImages = vacantHomeCleaning;
  } else if (subcategory.toLowerCase().includes("kitchen")) {
    titlesAndImages = kitchenCleaning;
  
} else if (subcategory.toLowerCase().includes("sofa")) {
  titlesAndImages = sofaCleaning;
} else if (subcategory.toLowerCase().includes("after")) {
  titlesAndImages = afterinteriorCleaning;
} else if (subcategory.toLowerCase().includes("office")) {
  titlesAndImages = officeCleaning;
} else if (subcategory.toLowerCase().includes("mattress")) {
  titlesAndImages = mattressCleaning;
} else if (subcategory.toLowerCase().includes("mini")) {
  titlesAndImages = miniCleaning;
} else if (subcategory.toLowerCase().includes("floor")) {
  titlesAndImages = floorCleaning;
} else if (subcategory.toLowerCase().includes("terrace")) {
  titlesAndImages = terraceCleaning;
} else if (subcategory.toLowerCase().includes("tank")) {
  titlesAndImages = tankCleaning;
} else if (subcategory.toLowerCase().includes("cockroach")) {
  titlesAndImages = cockroachControl;
} else if (subcategory.toLowerCase().includes("general")) {
  titlesAndImages = generalControl;
} else if (subcategory.toLowerCase().includes("bedbugs")) {
  titlesAndImages = bedbugsControl;
} else if (subcategory.toLowerCase().includes("mosquito")) {
  titlesAndImages = mosquitoControl;
} else if (subcategory.toLowerCase().includes("termite")) {
  titlesAndImages = termiteControl;
} else if (subcategory.toLowerCase().includes("woodborer")) {
  titlesAndImages = woodborerControl;
}

const scrollToService1 = (index) => {
  // Calculate the scroll position incrementally based on the index
  const scrollOffset = 350 * (index + 1);

  // Smooth scroll to the calculated position
  window.scrollBy({
    top: scrollOffset, // Scrolls down by the calculated offset
    behavior: "smooth", // Smooth scrolling animation
  });

  console.log(`Scrolled down by ${scrollOffset}px for service-${index}`);
};

  return (
    <>
  
  <div className="mobile-subcategory">
  
  <div className="row mt-12"
  style={{
    display: "flex",
  justifyContent: "center",   // Centers the items
  gap: "30px",                // Creates 40px gaps between items
  marginTop: "20px",          // Adjusts the distance from the top
  paddingLeft: "30px",         // Removes padding on the left
  paddingRight: "0px",        // Removes padding on the right
  justifyContent: "flex-start", 
  }}
  >
 
    {titlesAndImages.slice(0,3).map((data, index) => (
      <div
      key={index}
      className=" col-3  text-center"
      onClick={() => scrollToService1(index)}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center align content
        
      }}
    >
        <img
          style={{
            width: "100px",   // Set fixed size for image
                height: "100px",  // Set fixed height for image
                padding:"5px",
                objectFit: "cover",  // Ensure image fits container without distortion
                borderRadius: "10px",       // Adds a 10px border-radius
                
          }}
          className="mb-1"
          src={data.image}
          alt={data.title}
        />
        <div
              className="poppins-light pb-2"
              style={{
                color: "black",
                textAlign: "center",
                margin: 0, // Remove margin
                padding: 0, // Remove padding
                border: "none", // Remove border if any
                
              }}
            >
          {data.title}
        </div>
      </div>
    ))}
  </div>




</div>


  <div className="web-subcategory">
    <div className="row">
      <div className="col-md-10">
        <div className="row">
          {titlesAndImages.map((data, index) => (
            <div
              key={index}
              className="col-md-4 mt-4 subcat-row text-center"
              onClick={() => scrollToService(index)}
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "100%",
                width: "100%",
              }}
            >
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "0px",
                  padding: "10px",
                  marginBottom: "20px",
                }}
                className="mb-2"
                src={data.image}
                alt={data.title}
              />
              <div
                className="poppins-medium pb-2"
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontWeight: "bold",
                  textAlign: "center",
                  display: "block",
                }}
              >
                {data.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  
  

</>




 

  );
};

export default Subcategory_Component;
