import view1 from "../../src/assests/view-1.png";
import view2 from "../../src/assests/view-2.png";
import view3 from "../../src/assests/view-3.png";
import pestbanner from "../../src/assests/view-banner-1.png";
import def1 from "../../src/assests/why.jpg";
import def2 from "../../src/assests/11.jpg";
import def3 from "../../src/assests/one.jpg";
import vijayBanner from "../../src/assests/carpet7.jpg";
import cview1 from "../../src/assests/cview1.png";
import cview2 from "../../src/assests/cview2.png";
import cview3 from "../../src/assests/cview3.png";
import cview4 from "../../src/assests/cview4.png";
import cleaningbanner from "../../src/assests/view-banner-c.png";
import pview1 from "../../src/assests/pview1.png";
import pview2 from "../../src/assests/pview2.png";
import pview3 from "../../src/assests/pview3.png";
import pview4 from "../../src/assests/pview4.png";
import paintingbanner from "../../src/assests/view-banner-p.png";
import aview1 from "../../src/assests/aview1.png";
import aview2 from "../../src/assests/aview2.png";
import aview3 from "../../src/assests/aview3.png";
import aview4 from "../../src/assests/aview4.png";
import appliancebanner from "../../src/assests/view-banner-a.png";
import paintingbenefits from "../../src/assests/benefits-p.png";
import appliancebenefits from "../../src/assests/benefits-a.png";
import cleaningbenefits from "../../src/assests/benefits-c.png";
import pestbenefits from "../../src/assests/benefits-pest.png";
import pestbenefitsweb from "../../src/assests/pestControlBenefits.png";
import pestbannerweb from "../../src/assests/pestControlBanner.png";
import appliancebenefitsweb from "../../src/assests/appliancebenefits11.png";
import appliancebannerweb from "../../src/assests/applianceBanner.png";
import paintingbenefitsweb from "../../src/assests/paintingBenefits.png";
import paintingbannerweb from "../../src/assests/paintingBanner.png";
import cleaningbenefitsweb from "../../src/assests/cleaningBenefits.png";
import cleaningbannerweb from "../../src/assests/cleaningBanner.png";


const categoryData = {
  control: { // Using serviceSlug in a lowercase hyphenated format
    
    warranty: [
      "Single Service: 1 visit, no warranty.",
      "8-Month Plan: 2 visits, covered under warranty.",
      "1-Year AMC: 3 services (1 service every 4 months).",
      "2-Year AMC: 6 services (1 service every 4 months)."
    ],
    termsAndConditions: [
      "Effectiveness: Full results are achieved 15 days after service.",
      "Scheduling: Services must be utilized within 30 days of the scheduled date.",
      "Hygiene Maintenance: Maintaining proper kitchen hygiene is essential for optimal results."
    ],
    howWeWork: [
      {
        heading: "Comprehensive Inspection",
        description: "Our pest control experts thoroughly inspect your space to locate cockroaches, ants, and other pests hidden in tricky areas.",
        image: view1
      },
      {
        heading: "Unique 2-Visit Treatment",
        description: "The first visit involves a spray treatment to eliminate adult pests, while the second visit, conducted after two weeks, includes a gel treatment to target nymphs and newly hatched eggs.",
        image: view2
      },
      {
        heading: "Breaks the Pest Lifecycle",
        description: "This dual-visit approach eliminates adult pests in the first treatment and disrupts the lifecycle by targeting eggs and nymphs during the second.",
        image: view3
      }
    ],
    benefitsImage: pestbenefits,
    benefitsImage1:pestbenefitsweb,
    bannerImage1: pestbannerweb,
    bannerImage: pestbanner
  },

  pest: { // Using serviceSlug in a lowercase hyphenated format
    
    warranty: [
      "Single Service: 1 visit, no warranty.",
      "8-Month Plan: 2 visits, covered under warranty.",
      "1-Year AMC: 3 services (1 service every 4 months).",
      "2-Year AMC: 6 services (1 service every 4 months)."
    ],
    termsAndConditions: [
      "Effectiveness: Full results are achieved 15 days after service.",
      "Scheduling: Services must be utilized within 30 days of the scheduled date.",
      "Hygiene Maintenance: Maintaining proper kitchen hygiene is essential for optimal results."
    ],
    howWeWork: [
      {
        heading: "Comprehensive Inspection",
        description: "Our pest control experts thoroughly inspect your space to locate cockroaches, ants, and other pests hidden in tricky areas.",
        image: view1
      },
      {
        heading: "Unique 2-Visit Treatment",
        description: "The first visit involves a spray treatment to eliminate adult pests, while the second visit, conducted after two weeks, includes a gel treatment to target nymphs and newly hatched eggs.",
        image: view2
      },
      {
        heading: "Breaks the Pest Lifecycle",
        description: "This dual-visit approach eliminates adult pests in the first treatment and disrupts the lifecycle by targeting eggs and nymphs during the second.",
        image: view3
      }
    
    ],
    benefitsImage: pestbenefits,
    benefitsImage1:pestbenefitsweb,
    bannerImage1: pestbannerweb,
    bannerImage: pestbanner
  },

  painting: { 
    
    warranty: [
       'The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within  3 days of the original service date, subject to inspection and approval by our service team.'
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "On-time Inspection",
        description: "Our expert team will visit your home promptly to understand your vision.",
        image: pview1
      },
      {
        heading: "Free Color Consultancy",
        description: "Our expert team will provide you with proper color consultation.",
        image: pview2
      },
      {
        heading: "Accurate Quotation",
        description: "With advanced measuring tools, we will provide an accurate quotation.",
        image: pview3
      },
      {
        heading: "On-time Project Handover",
        description: "With our trained in-house team, we ensure timely job completion.",
        image: pview4
      }
    
    ],
    benefitsImage: paintingbenefits,
    bannerImage: paintingbanner,
    benefitsImage1:paintingbenefitsweb,
    bannerImage1: paintingbannerweb,
  },

  texture: { 
    
    warranty: [
       'The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within  3 days of the original service date, subject to inspection and approval by our service team.'
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "On-time Inspection",
        description: "Our expert team will visit your home promptly to understand your vision.",
        image: pview1
      },
      {
        heading: "Free Color Consultancy",
        description: "Our expert team will provide you with proper color consultation.",
        image: pview2
      },
      {
        heading: "Accurate Quotation",
        description: "With advanced measuring tools, we will provide an accurate quotation.",
        image: pview3
      },
      {
        heading: "On-time Project Handover",
        description: "With our trained in-house team, we ensure timely job completion.",
        image: pview4
      }
    
    ],
    benefitsImage: paintingbenefits,
    bannerImage: paintingbanner,
    benefitsImage1:paintingbenefitsweb,
    bannerImage1: paintingbannerweb,
  },

  polish: { 
    
    warranty: [
       'The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within  3 days of the original service date, subject to inspection and approval by our service team.'
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "On-time Inspection",
        description: "Our expert team will visit your home promptly to understand your vision.",
        image: pview1
      },
      {
        heading: "Free Color Consultancy",
        description: "Our expert team will provide you with proper color consultation.",
        image: pview2
      },
      {
        heading: "Accurate Quotation",
        description: "With advanced measuring tools, we will provide an accurate quotation.",
        image: pview3
      },
      {
        heading: "On-time Project Handover",
        description: "With our trained in-house team, we ensure timely job completion.",
        image: pview4
      }
    
    ],
    benefitsImage: paintingbenefits,
    bannerImage: paintingbanner,
    benefitsImage1:paintingbenefitsweb,
    bannerImage1: paintingbannerweb,
  },



  in: { 
    
    warranty: [
       'The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within  3 days of the original service date, subject to inspection and approval by our service team.'
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "On-time Inspection",
        description: "Our expert team will visit your home promptly to understand your vision.",
        image: pview1
      },
      {
        heading: "Free Color Consultancy",
        description: "Our expert team will provide you with proper color consultation.",
        image: pview2
      },
      {
        heading: "Accurate Quotation",
        description: "With advanced measuring tools, we will provide an accurate quotation.",
        image: pview3
      },
      {
        heading: "On-time Project Handover",
        description: "With our trained in-house team, we ensure timely job completion.",
        image: pview4
      }
    
    ],
    benefitsImage: paintingbenefits,
    bannerImage: paintingbanner,
    benefitsImage1:paintingbenefitsweb,
    bannerImage1: paintingbannerweb,
  },

  services: { 
    
    warranty: [
       'The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within  3 days of the original service date, subject to inspection and approval by our service team.'
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "On-time Inspection",
        description: "Our expert team will visit your home promptly to understand your vision.",
        image: pview1
      },
      {
        heading: "Free Color Consultancy",
        description: "Our expert team will provide you with proper color consultation.",
        image: pview2
      },
      {
        heading: "Accurate Quotation",
        description: "With advanced measuring tools, we will provide an accurate quotation.",
        image: pview3
      },
      {
        heading: "On-time Project Handover",
        description: "With our trained in-house team, we ensure timely job completion.",
        image: pview4
      }
    
    ],
    benefitsImage: paintingbenefits,
    bannerImage: paintingbanner,
    benefitsImage1:paintingbenefitsweb,
    bannerImage1: paintingbannerweb,
  },

  flat: { 
    
    warranty: [
       'The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within  3 days of the original service date, subject to inspection and approval by our service team.'
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "On-time Inspection",
        description: "Our expert team will visit your home promptly to understand your vision.",
        image: pview1
      },
      {
        heading: "Free Color Consultancy",
        description: "Our expert team will provide you with proper color consultation.",
        image: pview2
      },
      {
        heading: "Accurate Quotation",
        description: "With advanced measuring tools, we will provide an accurate quotation.",
        image: pview3
      },
      {
        heading: "On-time Project Handover",
        description: "With our trained in-house team, we ensure timely job completion.",
        image: pview4
      }
    
    ],
    benefitsImage: paintingbenefits,
    bannerImage: paintingbanner,
    benefitsImage1:paintingbenefitsweb,
    bannerImage1: paintingbannerweb,
  },

  day: { 
    
    warranty: [
       'The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within  3 days of the original service date, subject to inspection and approval by our service team.'
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "On-time Inspection",
        description: "Our expert team will visit your home promptly to understand your vision.",
        image: pview1
      },
      {
        heading: "Free Color Consultancy",
        description: "Our expert team will provide you with proper color consultation.",
        image: pview2
      },
      {
        heading: "Accurate Quotation",
        description: "With advanced measuring tools, we will provide an accurate quotation.",
        image: pview3
      },
      {
        heading: "On-time Project Handover",
        description: "With our trained in-house team, we ensure timely job completion.",
        image: pview4
      }
    
    ],
    benefitsImage: paintingbenefits,
    bannerImage: paintingbanner,
    benefitsImage1:paintingbenefitsweb,
    bannerImage1: paintingbannerweb,
  },
  
  cleaning: { 
      
    warranty: [
       "The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within 3 days of the original service date, subject to inspection and approval by our service team.",
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "Dust Removal",
        description: "We begin by eliminating loose dust from all surfaces, preparing your space for a deeper clean.",
        image: cview1
      },
      {
        heading: "Stain Removal",
        description: "Persistent stains are addressed using safe and effective methods to restore cleanliness and shine.",
        image: cview2
      },
      {
        heading: "Surface Wiping",
        description: "Wet or dry wiping is performed to remove dirt and grime, ensuring all surfaces are hygienic.",
        image: cview3
      },
      {
        heading: "Vacuuming",
        description: "Advanced vacuuming techniques are employed to extract dirt, dust, and allergens, leaving your space refreshed and spotless.",
        image: cview4
      }
    
    ],
    benefitsImage: cleaningbenefits,
    bannerImage: cleaningbanner,
    benefitsImage1:cleaningbenefitsweb,
    bannerImage1: cleaningbannerweb,
  },

  home: { 
      
    warranty: [
       "The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within 3 days of the original service date, subject to inspection and approval by our service team.",
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "Dust Removal",
        description: "We begin by eliminating loose dust from all surfaces, preparing your space for a deeper clean.",
        image: cview1
      },
      {
        heading: "Stain Removal",
        description: "Persistent stains are addressed using safe and effective methods to restore cleanliness and shine.",
        image: cview2
      },
      {
        heading: "Surface Wiping",
        description: "Wet or dry wiping is performed to remove dirt and grime, ensuring all surfaces are hygienic.",
        image: cview3
      },
      {
        heading: "Vacuuming",
        description: "Advanced vacuuming techniques are employed to extract dirt, dust, and allergens, leaving your space refreshed and spotless.",
        image: cview4
      }
    
    ],
    benefitsImage: cleaningbenefits,
    bannerImage: cleaningbanner,
    benefitsImage1:cleaningbenefitsweb,
    bannerImage1: cleaningbannerweb,
  },
    interior: { 
      
    warranty: [
       "The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within 3 days of the original service date, subject to inspection and approval by our service team.",
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "Dust Removal",
        description: "We begin by eliminating loose dust from all surfaces, preparing your space for a deeper clean.",
        image: cview1
      },
      {
        heading: "Stain Removal",
        description: "Persistent stains are addressed using safe and effective methods to restore cleanliness and shine.",
        image: cview2
      },
      {
        heading: "Surface Wiping",
        description: "Wet or dry wiping is performed to remove dirt and grime, ensuring all surfaces are hygienic.",
        image: cview3
      },
      {
        heading: "Vacuuming",
        description: "Advanced vacuuming techniques are employed to extract dirt, dust, and allergens, leaving your space refreshed and spotless.",
        image: cview4
      }
    
    ],
    benefitsImage: cleaningbenefits,
    bannerImage: cleaningbanner,
    benefitsImage1:cleaningbenefitsweb,
    bannerImage1: cleaningbannerweb,
  },
  
  and: { 
      
    warranty: [
       "The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within 3 days of the original service date, subject to inspection and approval by our service team.",
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "Dust Removal",
        description: "We begin by eliminating loose dust from all surfaces, preparing your space for a deeper clean.",
        image: cview1
      },
      {
        heading: "Stain Removal",
        description: "Persistent stains are addressed using safe and effective methods to restore cleanliness and shine.",
        image: cview2
      },
      {
        heading: "Surface Wiping",
        description: "Wet or dry wiping is performed to remove dirt and grime, ensuring all surfaces are hygienic.",
        image: cview3
      },
      {
        heading: "Vacuuming",
        description: "Advanced vacuuming techniques are employed to extract dirt, dust, and allergens, leaving your space refreshed and spotless.",
        image: cview4
      }
    
    ],
    benefitsImage: cleaningbenefits,
    bannerImage: cleaningbanner,
    benefitsImage1:cleaningbenefitsweb,
    bannerImage1: cleaningbannerweb,
  },
  deep: { 
      
    warranty: [
       "The Company provides service without any warranties, either express or implied. If the customer is not satisfied with the initial service, we offer Complimentary re-service within 3 days of the original service date, subject to inspection and approval by our service team.",
    ],
    termsAndConditions: [
      "Re-service must be requested within 24 hours of the original service date.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",
    ],
    howWeWork: [
      {
        heading: "Dust Removal",
        description: "We begin by eliminating loose dust from all surfaces, preparing your space for a deeper clean.",
        image: cview1
      },
      {
        heading: "Stain Removal",
        description: "Persistent stains are addressed using safe and effective methods to restore cleanliness and shine.",
        image: cview2
      },
      {
        heading: "Surface Wiping",
        description: "Wet or dry wiping is performed to remove dirt and grime, ensuring all surfaces are hygienic.",
        image: cview3
      },
      {
        heading: "Vacuuming",
        description: "Advanced vacuuming techniques are employed to extract dirt, dust, and allergens, leaving your space refreshed and spotless.",
        image: cview4
      }
    
    ],
    benefitsImage: cleaningbenefits,
    bannerImage: cleaningbanner,
    benefitsImage1:cleaningbenefitsweb,
    bannerImage1: cleaningbannerweb,
  },
  

  machine: { 
      
    warranty: [
       "30-Day Repair Warranty: Repairs are covered under warranty for 30 days to ensure lasting results.",
        "Free Repairs for Recurring Issues: If the same problem recurs within the warranty period, it will be fixed at no additional cost.",
        "Damage Coverage : Enjoy up to ₹10,000 coverage for any damages caused during the repair process.",
      
    ],
    termsAndConditions: [
      "Re-services should be claimed within 24 hours post-service.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",

    ],
    howWeWork: [
      {
        heading: "Pre-Service Inspection",
        description: "A thorough inspection, including a gas pressure check, to identify potential repairs and ensure optimal performance.",
        image: aview1
      },
      {
        heading: "Protective Measures",
        description: "Use of an AC service jacket to prevent spills and maintain a clean work area during and after the service.",
        image: aview2
      },
      {
        heading: "Power-Jet Cleaning",
        description: "Deep cleaning of filters, coils, fins, and drain trays to remove accumulated dirt and debris.",
        image: aview3
      },
      {
        heading: "Final Checks and Clean-Up",
        description: "Post-service checks to ensure everything is functioning correctly, followed by a thorough clean-up of the workspace.",
        image: aview4
      }
    
    ],
    benefitsImage: appliancebenefits,
    bannerImage: appliancebanner,
    benefitsImage1: appliancebenefitsweb,
    bannerImage1: appliancebannerweb,
  },
  repairing: { 
      
    warranty: [
       "30-Day Repair Warranty: Repairs are covered under warranty for 30 days to ensure lasting results.",
        "Free Repairs for Recurring Issues: If the same problem recurs within the warranty period, it will be fixed at no additional cost.",
        "Damage Coverage : Enjoy up to ₹10,000 coverage for any damages caused during the repair process.",
      
    ],
    termsAndConditions: [
      "Re-services should be claimed within 24 hours post-service.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",

    ],
    howWeWork: [
      {
        heading: "Pre-Service Inspection",
        description: "A thorough inspection, including a gas pressure check, to identify potential repairs and ensure optimal performance.",
        image: aview1
      },
      {
        heading: "Protective Measures",
        description: "Use of an AC service jacket to prevent spills and maintain a clean work area during and after the service.",
        image: aview2
      },
      {
        heading: "Power-Jet Cleaning",
        description: "Deep cleaning of filters, coils, fins, and drain trays to remove accumulated dirt and debris.",
        image: aview3
      },
      {
        heading: "Final Checks and Clean-Up",
        description: "Post-service checks to ensure everything is functioning correctly, followed by a thorough clean-up of the workspace.",
        image: aview4
      }
    
    ],
    benefitsImage: appliancebenefits,
    bannerImage: appliancebanner,
    benefitsImage1: appliancebenefitsweb,
    bannerImage1: appliancebannerweb,
  },


  ac: { 
      
    warranty: [
       "30-Day Repair Warranty: Repairs are covered under warranty for 30 days to ensure lasting results.",
        "Free Repairs for Recurring Issues: If the same problem recurs within the warranty period, it will be fixed at no additional cost.",
        "Damage Coverage : Enjoy up to ₹10,000 coverage for any damages caused during the repair process.",
      
    ],
    termsAndConditions: [
      "Re-services should be claimed within 24 hours post-service.",
      "The original invoice must be presented.",
      "The same service scope applies to the re-service.",
      "Additional services or upgrades are not included in the re-service.",

    ],
    howWeWork: [
      {
        heading: "Pre-Service Inspection",
        description: "A thorough inspection, including a gas pressure check, to identify potential repairs and ensure optimal performance.",
        image: aview1
      },
      {
        heading: "Protective Measures",
        description: "Use of an AC service jacket to prevent spills and maintain a clean work area during and after the service.",
        image: aview2
      },
      {
        heading: "Power-Jet Cleaning",
        description: "Deep cleaning of filters, coils, fins, and drain trays to remove accumulated dirt and debris.",
        image: aview3
      },
      {
        heading: "Final Checks and Clean-Up",
        description: "Post-service checks to ensure everything is functioning correctly, followed by a thorough clean-up of the workspace.",
        image: aview4
      }
    
    ],
    benefitsImage: appliancebenefits,
    bannerImage: appliancebanner,
    benefitsImage1: appliancebenefitsweb,
    bannerImage1: appliancebannerweb,
  },
  default: {
    warranty: [ 
      "The warranty period and coverage vary depending on the service plan you choose.",
      "We ensure that the service is completed with the highest quality.",
      "Customized Service: We focus on what matters most to you, making sure you're satisfied.",
      
    ],
    termsAndConditions: [
      "Effectiveness: Full results are achieved 15 days after service.",
      "Scheduling: Services must be utilized within 30 days of the scheduled date.",
      "Hygiene Maintenance: Maintaining proper kitchen hygiene is essential for optimal results."
    ],
    howWeWork: [
      {
        heading: "Comprehensive Inspection",
        description: "Our experts inspect the entire area before proceeding with any service to identify the exact needs.",
        image: def1
      },
      {
        heading: "Detailed Service Plan",
        description: "A clear service plan is outlined based on your needs, whether it's cleaning, repairs, or pest control.",
        image: def2
      },
      {
        heading: "Efficient Service Execution",
        description: "We ensure that the service is carried out with utmost professionalism and in the shortest time possible, while maintaining quality.",
        image: def3
      }
    ],
    benefitsImage: cleaningbenefits,
    bannerImage: vijayBanner
  }
};



export default categoryData;
