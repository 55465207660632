import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const GarbageRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const garbageUrls = [
      "/services/vacant-home-deep-cleaning-in-joinus",
      "/services/school-housekeeping-services-in-ahmedabad",
      "/services/office-housekeeping-services-in-ahmedabad-in-",
      "/services/sofa-cleaning-in-ahmedabad-in-",
      "/services/bathroom-cleaning-in-joinus",
      "/services/commercial-and-shopping-mall-housekeeping-services-in-ahmedabad",
      "/services/mosaic-polishing-in-ahmedabad",
      "/services/after-interior--deep-cleaning-in-ahmedabad-in-",
      "/services/1-day-painting-in-blogs",
      "/services/floor-cleaning-in-packers-movers",
      "/services/cleaning-service-in-hyderabad-in--in--in-",
      "/services/bedbugs-control-in-product-search",
      "/services/pest-control-in-ahmedabad-in-",
      "/services/termite-control-in-joinus",
      "/services/cockroach-control-in-repair",
      "/services/tank-and-sump-cleaning-in-ahmedabad-in-",
      "/services/tank-and-sump-cleaning-in-pm-mybookings",
      "/services/mosaic-polishing-in-ahmedabad",
      "/services/vacant-home-deep-cleaning-in-joinus",
      "/services/terrace-cleaning-in-home-in-",
      "/services/painting-service-in-ahmedabad-in-",
      "/services/grouting-services-in-service",
      "/services/grouting-services-in-appliances-repairing-services",
      "/services/grouting-services-in-commercial-pestcontrol",
      "/services/rental-painting-in-repairing",
      "/services/waterproofing-in-repair",
      "/services/mosquitoes-control-in-repair",
      "/services/general-pest-control-in-search",
      "/services/after-interior--deep-cleaning-in-ghaziabad",
"/services/floor-cleaning-in-gaziabad-in--in-",
"/services/exterior-painting-in-villa-cleaning-in-gaziabad",
"/services/exterior-texture-in-joinus",
"/services/wood-polish-in-",
"/services/wood-polish-in-villa-cleaning-in-gaziabad",
"/services/mosquitoes-control-in-blogs",
"/services/woodborer-control-in-ncr-in-",
"/services/mini-cleaning-services-in-carpet-cleaning-in-gurugram",
"/services/tank-and-sump-cleaning-in-sump-cleaning-in-gurugram",
"/services/rental-painting-in-tank-cleaning-in-gurugram",
"/services/interior-painting-in-tank-cleaning-in-gurugram",
"/services/exterior-texture-in-painting-charges-in-gurugram",
"/services/wood-polish-in-gurugram-in-",
"/services/wood-polish-in-painting-charges-in-gurugram",
"/services/grouting-services-in-villa-cleaning-in-gurugram",
"/services/bathroom-cleaning-in-painting-charges-in-gurugram",
"/services/1-day-painting-in-painting-charges-in-gurugram",
"/services/bedbugs-control-in-villa-cleaning-in-gurugram",
"/services/bed-bug-treatment-in-gurugram",
"/services/bedbugs-control-in-gurugram-in-",
"/services/mosquitoes-control-in-carpet-cleaning-in-gurugram",
"/services/mosquitoes-control-in-blogs",
"/services/woodborer-control-in-villa-cleaning-in-gurugram",
"/services/woodborer-control-in-ncr-in-",
"/services/commercial-pest-control-in-carpet-cleaning-in-gurugram",
"/services/sofa-cleaning-in-villa-cleaning-in-kochi",
"/services/kitchen-cleaning-in-tank-cleaning-in-kochi",
"/services/vacant-home-deep-cleaning-in-joinus",
"/services/occupied-home-deep-cleaning-in-villa-cleaning-in-kochi",
"/services/after-interior-deep-cleaning-in-painting-charges-in-kochi",
"/services/office-cleaning-in-carpet-cleaning-in-kochi",
"/services/mattress-cleaning-in-packers-movers",
"/services/mini-cleaning-services-in-villa-cleaning-in-kochi",
"/services/mini-cleaning-services-in-joinus",
"/services/granite-floor-polishing-in-villa-cleaning-in-kochi",
"/services/mosaic-floor-polishing-in-tank-cleaning-in-kochi",
"/services/floor-cleaning-in-tank-cleaning-in-kochi",
"/services/terrace-cleaning-in-villa-cleaning-in-kochi",
"/services/cleaning-services-in-best-cleaning-service",
"/services/cleaning-services-in-tank-cleaning-in-kochi",
"/services/rental-painting-in-joinus",
"/services/interior-painting-in-villa-cleaning-in-kochi",
"/services/exterior-painting-in-villa-cleaning-in-kochi",
"/services/exterior-texture-in-sump-cleaning-in-kochi",
"/services/exterior-texture-in-painting-charges-in-kochi",
"/services/wood-polish-in-sump-cleaning-in-kochi",
"/services/grouting-services-in-painting-charges-in-kochi",
"/services/grouting-services-in-painting-charges-in-kochi",
"/services/grouting-services-in-appliances-repairing-services",
"/services/bathroom-cleaning-in-painting-charges-in-kochi",
"/services/vacant-flat-painting-in-carpet-cleaning-in-kochi",
"/services/vacant-flat-painting-in-joinus-in--in-",
"/services/1-day-painting-in-painting-charges-in-kochi",
"/services/cockroach-control-in-villa-cleaning-in-kochi",
"/services/general-pest-control-in-villa-cleaning-in-kochi",
"/services/bedbugs-control-in-sump-cleaning-in-kochi",
"/services/mosquitoes-control-in-blogs",
"/services/commercial-pest-control-in-kochi-in-",
"/services/general-pest-control-in-villa-cleaning-in-kolkata",
"/services/commercial-pest-control-in-sump-cleaning-in-kolkata",
"/services/pest-control-amc-in-kolkata-in-",
"/services/bathroom-cleaning-in-villa-cleaning-in-kolkata",
"/services/subscribe-bathroom-deepcleaning-services-in-kolkata-in-",
"/services/sofa-cleaning-in-packers-movers",
"/services/kitchen-cleaning-in-tank-cleaning-in-kolkata",
"/services/vacant-home-deep-cleaning-in-sump-cleaning-in-kolkata",
"/services/occupied-home-deep-cleaning-in-tank-cleaning-in-kolkata",
"/services/after-interior-deep-cleaning-in-sump-cleaning-in-kolkata",
"/services/office-housekeeping-services-in-kolkata",
"/services/mattress-cleaning-in-sump-cleaning-in-kolkata",
"/services/mini-cleaning-services-in-carpet-cleaning-in-kolkata",
"/services/floor-cleaning-in-villa-cleaning-in-kolkata",
"/services/mosaic-floor-polishing-in-villa-cleaning-in-kolkata",
"/services/granite-floor-polishing-in-tank-cleaning-in-kolkata",
"/services/tank-and-sump-cleaning-in-service",
"/services/cleaning-services-in-best-cleaning-service",
"/services/rental-painting-in-painting-charges-in-kolkata",
"/services/interior-painting-in-painting-charges-in-kolkata",
"/services/exterior-painting-in-painting-charges-in-kolkata",
"/services/exterior-cleaning-in-kolkata",
"/services/exterior-texture-in-painting-charges-in-kolkata",
"/services/exterior-texture-in-villa-cleaning-in-kolkata",
"/services/wood-polish-in-carpet-cleaning-in-kolkata",
"/services/wood-polish-in-joinus",
"/services/waterproofing-in-villa-cleaning-in-kolkata",
"/services/painting-services-in-ac-repair",
"/services/grouting-services-in-sump-cleaning-in-kolkata",
"/services/vacant-flat-painting-in-carpet-cleaning-in-kolkata",
"/services/1-day-painting-in-painting-charges-in-kolkata",
"/services/cockroach-control-in-villa-cleaning-in-kolkata",
"/services/bedbugs-control-in-tank-cleaning-in-kolkata",
"/services/bedbugs-control-in-villa-cleaning-in-kolkata",
"/services/pest-control-amc-in-kolkata-in-",
"/services/mosquitoes-control-in-tank-cleaning-in-kolkata",
"/services/mosquitoes-control-in-repair",
"/services/commercial-pest-control-in-carpet-cleaning-in-kolkata",
"/services/woodborer-control-in-villa-cleaning-in-kolkata",
"/services/commercial-pest-control-in-tank-cleaning-in-kolkata",
"/services/occupied-home-deep-cleaning-in-villa-cleaning-in-delhi",
"/services/vacant-home-deep-cleaning-in-sump-cleaning-in-delhi",
"/services/office-cleaning-in-delhi-ncr",
"/services/cleaning-services-in-sump-cleaning-in-ncr",
"/services/rental-painting-in--ncr-in-",
"/services/interior-painting-in-sump-cleaning-in-delhi",
"/services/exterior-painting-in-delhi-ncr-in-",
"/services/exterior-texture-in-delhi-ncr",
"/services/wood-polish-in-sump-cleaning-in-delhi",
"/services/vacant-flat-painting-in-painting-charges-in-delhi",
"/services/1-day-painting-in-painting-charges-in-ncr",
"/services/1-day-painting-in-sump-cleaning-in-delhi",
"/services/1-day-painting-in-home",
"/services/bed-bug-treatment-in-gurugram",
"/services/mosquitoes-control-in-repair",
"/services/mosquitoes-control-in-blogs",
"/services/sofa-cleaning-in-home",
"/services/sofa-cleaning-in-packers-movers",
"/services/sofa-cleaning-in-home",
"/services/sofa-cleaning-in-packers-movers",
"/services/kitchen-cleaning-in-packers-movers",
"/services/vacant-home-deep-cleaning-in-joinus",
"/services/mattress-cleaning-in-packers-movers",
"/services/mini-cleaning-services-in-joinus",
"/services/floor-cleaning-in-service",
"/services/tank-and-sump-cleaning-in-service",
"/services/rental-painting-in-joinus",
"/services/exterior-texture-in-repair",
"/services/vacant-flat-painting-in-service",
"/services/cockroach-control-in-work-with-us-in-",
"/services/bathroom-cleaning-in-joinus",
"/services/kitchen-cleaning-in-packers-movers",
"/services/deep-cleaning-in-vizag-in-",
"/services/office-cleaning-in-cleaning-faqs",
"/services/mattress-cleaning-in-packers-movers",
"/services/mini-cleaning-services-in-joinus",
"/services/terrace-cleaning-in-services",
"/services/tank-and-sump-cleaning-in-code",
"/services/rental-painting-in-joinus",
"/services/interior-painting-in-joinus",
"/services/painting-services-in-packers-and-movers",
"/services/wood-polish-in-repair",
"/services/wallpaper-in-joinus-in-",
"/services/1-day-painting-in-code",
"/services/termite-control-in-joinus",
"/services/woodborer-control-in-joinus",
"/services/woodborer-control-in-home",
"/services/tank-and-sump-cleaning-in-bangalorebest",
"/services/mosaic-floor-polishing-in-villa-cleaning-in-bangalore",
"/services/cleaning-service-in-bangalore-in--in--in-",
"/services/exterior-texture-in-carpet-cleaning-in-bangalore",
"/services/waterproofing-in-painting-charges-in-bangalore",
"/services/best-cleaning-service?trk=public_post_comment-text",
"/services/woodborer-control-in-villa-cleaning-in-hyderabad",
"/services/bedbugs-control-in-in-hyderabad",
"/services/bedbugs-control-in-movers",
"/services/bedbugs-control-in-villa-cleaning-in-hyderabad",
"/services/bed-bug-treatment-in-hyderabad-in-",
"/services/mosquitoes-control-in-blogs",
"/services/mosquitoes-control-in-repair",
"/services/mosquitoes-control-in-villa-cleaning-in-hyderabad",
"/services/wallpaper-in-joinus",
"/services/bathroom-cleaning-in-painting-charges-in-hyderabad",
"/services/grouting-services-in-appliances-repairing-services",
"/services/grouting-services-in-service",
"/services/wood-polish-in-testimonies",
"/services/wood-polish-in-villa-cleaning-in-hyderabad",
"/services/mattress-cleaning-in-villa-cleaning-in-hyderabad",
"/services/termite-control-in-carpet-cleaning-in-hyderabad",
"/services/cockroach-control-in-carpet-cleaning-in-hyderabad",
"/services/general-pest-control-in-hyderabad-in-",
"/services/exterior-cleaning-in-hyderabad",
"/services/tank-and-sump-cleaning-in-service",
"/services/terrace-cleaning-in-villa-cleaning-in-hyderabad",
"/services/pest-control-in-carpet-cleaning-in-hyderabad",
"/services/sofa-cleaning-in-tank-cleaning-in-hyderabad",
"/services/kitchen-cleaning-in-painting-charges-in-hyderabad",
"/services/waterproofing-in-carpet-cleaning-in-hyderabad",
"/services/anti-virus-treatment-in-hyderabad-in-",
"/services/painting-services-in-painting-charges-in-hyderabad",
"/services/interior-painting-in-tank-cleaning-in-hyderabad",
"/services/exterior-cleaning-in-hyderabad",
"/services/1-day-painting-in-painting-charges-in-hyderabad",
"/services/1-day-painting-in-home",
"/services/floor-cleaning-in-villa-cleaning-in-hyderabad",
"/services/mosaic-floor-polishing-in-sump-cleaning-in-hyderabad",
"/services/cleaning-service-in-hyderabad-in-",
"/services/cleaning-service-in-hyderabad-in--in--in--in--in-",
"/services/bathroom-cleaning-in-sump-cleaning-in-hyderabad",
"/services/tank-and-sump-cleaning-in-carpet-cleaning-in-chennai",
"/services/bathroom-cleaning-in-sump-cleaning-in-chennai",
"/services/floor-cleaning-in-sump-cleaning-in-chennai",
"/services/waterproofing-in-search",
"/services/bathroom-cleaning-in-joinus",
"/services/tank-&-sump-cleaning-in-lucknow",
"/services/tank-and-sump-cleaning-in-service",
"/services/terrace-cleaning-in-service",
"/services/floor-cleaning-in-home",
"/services/exterior-texture-in-villa-cleaning-in-lucknow",
"/services/tank-and-sump-cleaning-in-sump-cleaning-in-lucknow",
"/services/general-pest-control-in-sump-cleaning-in-lucknow",
"/services/commercial-pest-control-in-carpet-cleaning-in-lucknow",
"/services/bedbugs-control-in-carpet-cleaning-in-lucknow",
"/services/cockroach-control-in-villa-cleaning-in-lucknow",
"/services/termite-control-in-lucknow-in--in-",
"/services/mosquitoes-control-in-blogs",
"/services/1-day-painting-in-tank-cleaning-in-lucknow",
"/services/general-pest-control-in-tank-cleaning-in-mumbai",
"/services/floor-cleaning-in-home",
"/services/tank-&-sump-cleaning-in-mumbai-in-",
"/services/exterior-texture-in-villa-cleaning-in-mumbai",
"/services/grouting-services-in-carpet-cleaning-in-mumbai",
"/services/grouting-services-in-appliances-repairing-services",
"/services/grouting-services-in-commercial-pestcontrol",
"/services/bathroom-cleaning-in-villa-cleaning-in-mumbai",
"/services/painting-services-in-terms",
"/services/1-day-painting-in-painting-charges-in-mumbai",
"/services/waterproofing-in-carpet-cleaning-in-mumbai",
"/services/mosquitoes-control-in-villa-cleaning-in-mumbai",
"/services/mosquitoes-control-in-repair",
"/services/mosquitoes-control-in-blogs",
"/services/cockroach-control-in-pune-in--in-",
"/services/mosquitoes-control-in-repair",
"/services/tank-&-sump-cleaning-in-pune",
"/services/cleaning-services-in-terms",
"/services/tank-and-sump-cleaning-in-service",
"/services/exterior-texture-in-repair",
"/services/exterior-cleaning-in-pune-in-",
"/services/exterior-painting-in-home",
"/services/waterproofing-in-service",
"/services/interior-painting-in-pune-in--in--in-",
"/services/pest-control-in-cancellation",
"/services/woodborer-control-in-repairing",
"/services/1-day-painting-in-painting-charges-in-mysore",
"/services/painting-services-in-home",
"/services/exterior-painting-in-sump-cleaning-in-mysore",
"/services/general-pest-control-in-sump-cleaning-in-mysore",
"/services/commercial-pest-control-in-tank-cleaning-in-mysore",
"/services/cockroach-control-in-sump-cleaning-in-mysore",
"/services/bedbugs-control-in-villa-cleaning-in-mysore",
"/services/bedbugs-control-in-ac-repairing",
"/services/woodborer-control-in-carpet-cleaning-in-mysore",
"/services/termite-control-in-tank-cleaning-in-mysore",
"/services/pest-control-in-servicedetails",
"/services/tank-and-sump-cleaning-in-mysore-in-",
"/services/tank-and-sump-cleaning-in-tank-cleaning-in-mysore",
"/services/interior-painting-in-villa-cleaning-in-mysore",
"/services/grouting-services-in-carpet-cleaning-in-mysore",
"/services/grouting-services-in-movers",
"/services/bathroom-cleaning-in-villa-cleaning-in-mysore",
"/services/office-cleaning-in-villa-cleaning-in-mysore",
"/services/floor-cleaning-in-tank-cleaning-in-mysore",
"/services/floor-cleaning-in-home",
"/services/granite-floor-polishing-in-carpet-cleaning-in-mysore",
"/services/mosaic-floor-polishing-in-carpet-cleaning-in-mysore",
"/services/bedbugs-control-in-mysore-in-",
"/services/vacant-flat-painting-in-sump-cleaning-in-mysore",
"/services/mosquitoes-control-in-painting-charges-in-mysore",
"/services/mosquitoes-control-in-repair",
"/services/woodborer-control-in-villa-cleaning-in-mysore",
"/services/commercial-pest-control-in-tank-cleaning-in-mysore",
"/services/pest-control-amc-in-noida-in-",
"/services/floor-cleaning-in-home",
"/services/office-cleaning-in-noida-in-",
"/services/cleaning-services-in-best-cleaning-service",
"/services/tank-and-sump-cleaning-in-service",
"/services/cleaning-services-in-best-cleaning-service",
"/services/exterior-cleaning-in-surat-in-",
"/services/tank-and-sump-cleaning-in-service",
"/services/tank-&-sump-cleaning-in-surat",
"/services/exterior-texture-in-repair",
"/services/exterior-cleaning-in-surat-in-",
"/services/waterproofing-in-repair",
"/services/exterior-texture-in-repair",
"/services/exterior-cleaning-in-vadodara",
"/services/waterproofing-in-service",
"/services/exterior-cleaning-in-vadodara",
"/services/pest-control-in-courses",
"/services/general-pest-control-in-tank-cleaning-in-bhubaneswar",
"/services/commercial-pest-control-in-carpet-cleaning-in-bhubaneswar",
"/services/bathroom-cleaning-in-villa-cleaning-in-bhubaneswar",
"/services/tank-and-sump-cleaning-in-service",
"/services/cleaning-services-in-best-cleaning-service",
"/services/mosaic-floor-polishing-in-villa-cleaning-in-bhubaneswar",
"/services/floor-cleaning-in-home",
"/services/waterproofing-in-tank-cleaning-in-bhubaneswar",
"/services/waterproofing-in-sump-cleaning-in-bhubaneswar",
"/services/general-pest-control-in-sump-cleaning-in-bhubaneswar",
"/services/pest-control-in-privacy",
"/services/commercial-pest-control-in-carpet-cleaning-in-bhubaneswar",
"/services/waterproofing-in-repairing",
"/services/office-cleaning-in-cleaning-faqs",
"/services/mattress-cleaning-in-packers-movers",
"/services/mini-cleaning-services-in-villa-cleaning-in-delhi",
"/services/mosaic-floor-polishing-in-sump-cleaning-in-delhi",
"/services/rental-painting-in-painting-charges-in-delhi",
"/services/interior-painting-in-painting-charges-in-delhi",
"/services/interior-painting-in-search",
"/services/exterior-painting-in-delhi-ncr-in-",
"/services/waterproofing-in-carpet-cleaning-in-delhi",
"/services/vacant-flat-painting-in-sump-cleaning-in-delhi",
"/services/1-day-painting-in-villa-cleaning-in-delhi",
"/services/cockroach-control-in-search",
"/services/mosquitoes-control-in-sump-cleaning-in-delhi",
"/services/mosquitoes-control-in-repair",
"/services/commercial-pest-control-in-painting-charges-in-delhi",
"/services/bathroom-cleaning-in-tank-cleaning-in-faridabad",
"/services/cleaning-services-in-villa-cleaning-in-faridabad",
"/services/kitchen-cleaning-in-joinus",
"/services/vacant-home-deep-cleaning-in-sump-cleaning-in-faridabad",
"/services/after-interior-deep-cleaning-in-carpet-cleaning-in-faridabad",
"/services/after-interior--deep-cleaning-in-faridabad",
"/services/mini-cleaning-services-in-tank-cleaning-in-faridabad",
"/services/floor-cleaning-in-carpet-cleaning-in-faridabad",
"/services/floor-cleaning-in-villa-cleaning-in-faridabad",
"/services/mosaic-floor-polishing-in-villa-cleaning-in-faridabad",
"/services/granite-floor-polishing-in-villa-cleaning-in-faridabad",
"/services/terrace-cleaning-in-carpet-cleaning-in-faridabad",
"/services/tank-and-sump-cleaning-in-pm-mybookings",
"/services/tank-and-sump-cleaning-in-service",
"/services/rental-painting-in-tank-cleaning-in-faridabad",
"/services/interior-painting-in-sump-cleaning-in-faridabad",
"/services/exterior-texture-in-villa-cleaning-in-faridabad",
"/services/kitchen-cleaning-in-sump-cleaning-in-faridabad",
"/services/painting-services-in-painting-charges-in-faridabad",
"/services/floor-cleaning-in-painting-charges-in-faridabad",
"/services/grouting-services-in-painting-charges-in-faridabad",
"/services/bathroom-cleaning-in-painting-charges-in-faridabad",
"/services/commercial-pest-control-in-painting-charges-in-faridabad",
"/services/floor-cleaning-in-painting-charges-in-faridabad",
"/services/bathroom-cleaning-in-painting-charges-in-faridabad",

    ];

    if (garbageUrls.includes(window.location.pathname)) {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  return null; // This component does not render anything
};

export default GarbageRedirect;
